import React from "react"
import { Box, Button, Typography } from "@mui/material"
import ArrowForward from "../../icons/ArrowForward"
import ArrowBack from "../../icons/ArrowBack"
import StartIconOutline from "../../icons/StartIconOutline"
import StartIconFill from "../../icons/StartIconFill"

interface Step {
  label: string
  completed: boolean
}

interface StepComponentProps {
  steps: Step[]
  activeStep: number
  onNext: () => void
  onBack: () => void
  isEditMode: boolean
  stepBody: React.ReactNode
}

const StepComponent = ({
  steps,
  activeStep,
  onNext,
  onBack,
  isEditMode,
  stepBody
}: StepComponentProps) => {
  const isCurrentStepComplete = steps[activeStep].completed

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
        gap: "32px",
        justifyContent: "space-between"
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginY: "24px",
          width: "100%"
        }}
      >
        {steps.map((step, index) => {
          const isCompleted = step.completed
          const isActive = activeStep === index

          let textColor, iconColor

          if (isCompleted) {
            textColor = "fileInput.dark"
            iconColor = "fileInput.dark"
          } else if (isActive) {
            textColor = "fileInput.dark"
            iconColor = "fileInput.dark"
          } else {
            textColor = "text.disabled"
            iconColor = "text.disabled"
          }

          return (
            <React.Fragment key={index}>
              {isCompleted ? (
                <StartIconFill sx={{ height: "20px", color: iconColor }} />
              ) : (
                <StartIconOutline sx={{ height: "20px", color: iconColor }} />
              )}
              <Typography
                variant="subtitle1"
                sx={{ color: textColor, marginLeft: "4px" }}
              >
                {step.label}
              </Typography>

              {index < steps.length - 1 && (
                <Box
                  sx={{
                    width: "60px",
                    height: "1px",
                    backgroundColor: "text.disabled",
                    mx: "12px"
                  }}
                />
              )}
            </React.Fragment>
          )
        })}
      </Box>
      <Box
        sx={{
          width: "100%",
          borderRadius: "8px",
          border: "1px solid",
          borderColor: "primary.contrastText",
          display: "flex",
          overflowY: "hidden",
          flexDirection: "column",
          marginBottom: "40px"
        }}
      >
        {stepBody}
      </Box>
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          boxShadow: "0px -4px 12px rgba(0, 0, 0, 0.08)",
          backgroundColor: "secondary.main"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "100%",
            padding: "20px 120px"
          }}
        >
          <Button variant="outlined" onClick={onBack}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: "600", color: "text.primary" }}
              >
                <ArrowBack sx={{ height: "12px" }} />
                {activeStep === 0 ? "Cancel" : "Previous"}
              </Typography>
            </Box>
          </Button>
          <Button
            variant="contained"
            disabled={!isCurrentStepComplete}
            type="button"
            onClick={onNext}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "600",
                  color: isCurrentStepComplete
                    ? "secondary.main"
                    : "secondary.dark",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {activeStep === steps.length - 1
                  ? isEditMode
                    ? "Save Changes"
                    : "Complete"
                  : "Continue"}
                <ArrowForward
                  sx={{
                    height: "12px",
                    color: isCurrentStepComplete ? "inherit" : "secondary.dark"
                  }}
                />
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default StepComponent
