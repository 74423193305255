import React from "react"
import { useController, useFormContext, Controller } from "react-hook-form"
import { Box, TextField, Typography } from "@mui/material"
import { Status } from "../../../models/campaign"
import DatePicker from "../../datepicker"
import dayjs from "dayjs"
import PromotionNoEndToggle from "../PromotionNoEndToggle"

type PromotionFormProps = {
  currentStartDate?: dayjs.Dayjs | undefined
  currentEndDate?: dayjs.Dayjs | undefined
  campaignStatus?: Status
}

const PromotionForm = ({
  currentStartDate,
  currentEndDate,
  campaignStatus
}: PromotionFormProps) => {
  const { control, formState, setError, setValue, watch } = useFormContext()
  const { errors } = formState

  const { field: startDateField } = useController({
    name: "startDate",
    control,
    rules: {
      required: "Start date is required"
    }
  })

  const { field: endDateField } = useController({
    name: "endDate",
    control,
    rules: {
      required: "End date is required"
    }
  })

  const startDateDisabled = currentStartDate
    ? dayjs(currentStartDate).isBefore(dayjs(), "day")
    : false

  const endDateDisabled = currentEndDate
    ? dayjs(currentEndDate).isBefore(dayjs(), "day")
    : false

  const isNoEndDate = watch("isNoEndDate")

  const handleNoEndDateChange = (checked: boolean) => {
    setValue("isNoEndDate", checked, { shouldValidate: true })
    if (checked) {
      setValue("endDate", dayjs(4092624000000), { shouldValidate: true })
    } else {
      setValue("endDate", undefined, { shouldValidate: true })
    }
  }
  const isCampaignEnded = campaignStatus === "Ended"

  return (
    <Box display="flex" flexDirection="column" gap="32px">
      <Box display="flex" flexDirection="column" gap="20px">
        <Box>
          <Typography
            variant="body2"
            component="label"
            display="flex"
            gap="2px"
            marginBottom="8px"
            color={isCampaignEnded ? "text.disabled" : "inherit"}
          >
            Name
            <Typography
              variant="body2"
              component="span"
              sx={{ color: "error.main" }}
            >
              *
            </Typography>
          </Typography>
          <Controller
            name="name"
            control={control}
            rules={{
              required: "Name is required"
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value ?? ""}
                variant="outlined"
                type="text"
                placeholder="Name your promotion"
                helperText={errors.name?.message as string}
                error={!!errors.name}
                disabled={isCampaignEnded}
                sx={{
                  width: "100%"
                }}
              />
            )}
          />
        </Box>
        <Box display="flex" gap="16px">
          <Box>
            <Controller
              name="startDate"
              control={control}
              rules={{
                required: "Start date is required"
              }}
              render={({ field }) => (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    gap="2px"
                    alignItems="center"
                    sx={{
                      margin: "0px",
                      padding: "0px",
                      marginBottom: "8px"
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="label"
                      display="flex"
                      gap="2px"
                      color={isCampaignEnded ? "text.disabled" : "inherit"}
                    >
                      Start date
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{ color: "error.main" }}
                    >
                      *
                    </Typography>
                  </Box>
                  <DatePicker
                    {...field}
                    disabled={startDateDisabled || isCampaignEnded}
                    label=""
                    value={field.value ? dayjs(field.value) : null}
                    minDate={dayjs()}
                    maxDate={endDateField.value}
                    errors={errors}
                    name="startDate"
                    onBlur={(event) => {
                      const newValue = event.target.value
                      if (dayjs(newValue).isBefore(dayjs(), "day")) {
                        setError("startDate", {
                          type: "manual",
                          message: "Invalid Start Date"
                        })
                      }
                    }}
                  />
                </>
              )}
            />
          </Box>

          <Box>
            <Controller
              name="endDate"
              control={control}
              rules={{
                required: "End date is required"
              }}
              render={({ field }) => (
                <>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    gap="2px"
                    alignItems="center"
                    sx={{
                      margin: "0px",
                      padding: "0px",
                      marginBottom: "8px"
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="label"
                      display="flex"
                      gap="2px"
                      color={isCampaignEnded ? "text.disabled" : "inherit"}
                    >
                      End date
                    </Typography>
                    <Typography
                      variant="body2"
                      component="span"
                      sx={{
                        color: isCampaignEnded ? "text.disabled" : "error.main"
                      }}
                    >
                      *
                    </Typography>
                  </Box>
                  <DatePicker
                    {...field}
                    value={
                      field.value && !isNoEndDate ? dayjs(field.value) : null
                    }
                    style={{
                      display: isNoEndDate ? "none" : "flex"
                    }}
                    disabled={
                      endDateDisabled ||
                      campaignStatus === "Ended" ||
                      isNoEndDate
                    }
                    placeholder={isNoEndDate ? "-" : "MM/DD/YYYY"}
                    label=""
                    minDate={
                      dayjs(startDateField?.value).isAfter(dayjs(), "day")
                        ? startDateField?.value
                        : dayjs()
                    }
                    errors={errors}
                    name="endDate"
                    onBlur={(event) => {
                      const newValue = event.target.value
                      if (
                        dayjs(newValue).isBefore(dayjs(), "day") ||
                        (startDateField?.value &&
                          dayjs(newValue).isBefore(
                            startDateField?.value,
                            "day"
                          ))
                      ) {
                        setError("endDate", {
                          type: "manual",
                          message: "Invalid End Date"
                        })
                      }
                    }}
                  />
                  <PromotionNoEndToggle
                    control={control}
                    isCampaignEnded={isCampaignEnded}
                    handleNoEndDateChange={handleNoEndDateChange}
                    style={{
                      display: isNoEndDate ? "flex" : "none",
                      paddingTop: "7px"
                    }}
                  />
                </>
              )}
            />
          </Box>
          {!isNoEndDate && (
            <PromotionNoEndToggle
              control={control}
              isCampaignEnded={isCampaignEnded}
              handleNoEndDateChange={handleNoEndDateChange}
              style={{
                display: "flex"
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default PromotionForm
