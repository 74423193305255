import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const CancelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.125 0.875L4 4M4 4L0.875 7.125M4 4L7.125 7.125M4 4L0.875 0.875"
          stroke="currentColor"
          strokeWidth="1.5"
        />
      </svg>
    </SvgIcon>
  )
}

export default CancelIcon
