import * as Yup from "yup"
import { ZonePlacement } from "./zoneplacement"
import { Dayjs } from "dayjs"
import dayjs from "dayjs"

export type ActiveStore = {
  id: number
  name: string
}

export type Campaign = {
  id: number
  activeStores: ActiveStore[]
  storeCount?: number
  activeSummary: string
  duration: number
  endDate: string
  name: string
  startDate: string
  status: string
  statusDisplay: string
  tmpVideo?: number | null
  tmpVideoDuration?: number | null
  videoId?: number | null
  video?: CampaignVideo
  storeAreasId?: number
  storeAreaName?: string
  isEvergreen?: boolean
}

export type PaginatedCampaign = {
  id: number
  campaignTypesId?: number
  campaignCategoriesId?: number
  companiesId: number
  startDate: string
  endDate: string
  isDeleted: boolean
  isCmsManaged: boolean
  name: string
  videosId?: number
  tmpVideos?: number
  type: number
  storeAreaName?: string
  status: string
  activeStoresCount: string
  activeSummary: string
  reach: string
}

export const StatusList = [
  "Scheduled",
  "Active",
  "Ending Soon",
  "Ended",
  "Draft"
] as const

export type Status = (typeof StatusList)[number]

export type Banner = {
  id: number
  name: string
}

export type Retailer = {
  id: number
  name: string
}

export type DeviceCountByStoreArea = {
  id: number
  deviceCount: string
}

export type StoreProperty = {
  property_id: number
  property_name: string
  value_id: string
  value_name: string
}

export type NewCampaignStore = {
  id: number
  name: string
  company_id: number
  installed: boolean
  banner: {
    id: number
    name: string
  }
  address: {
    street?: string
    city?: string
    state?: string
    postal_code?: string
  }
  properties: StoreProperty[]
}

export type CampaignStore = {
  storesId: number
  deviceCount: string
  storeName: string
  city: string
  state: string
  retailer?: Retailer
  banner?: Banner
  retailerName?: string
  bannerName?: string
  storeAreaIds?: number[]
  storeAreas: DeviceCountByStoreArea[]
  properties?: StoreProperty[]
}

export const mocks: Campaign[] = [
  {
    id: 1,
    name: "Campaign 1",
    startDate: "2021-01-01",
    endDate: "2021-01-31",
    status: "Scheduled",
    statusDisplay: "Scheduled",
    activeSummary: "0/10",
    duration: 10,
    activeStores: []
  }
]

const mockDeviceCountByStoreArea: DeviceCountByStoreArea[] = [
  {
    id: 1,
    deviceCount: "10"
  },
  {
    id: 2,
    deviceCount: "10"
  },
  {
    id: 3,
    deviceCount: "10"
  }
]

export const mockCampaignStores: CampaignStore[] = [
  {
    storesId: 1,
    storeName: "Store 1",
    deviceCount: "101",
    city: "Baltimore",
    state: "Maryland",
    storeAreas: mockDeviceCountByStoreArea
  },
  {
    storesId: 2,
    storeName: "Store 2",
    deviceCount: "9",
    city: "Baltimore",
    state: "Maryland",
    storeAreas: mockDeviceCountByStoreArea
  },
  {
    storesId: 3,
    storeName: "Store 3",
    deviceCount: "10",
    city: "Baltimore",
    state: "Maryland",
    storeAreas: mockDeviceCountByStoreArea
  },
  {
    storesId: 4,
    storeName: "Store 4",
    deviceCount: "10",
    city: "Baltimore",
    state: "Maryland",
    storeAreas: mockDeviceCountByStoreArea
  },
  {
    storesId: 5,
    storeName: "Store 5",
    deviceCount: "10",
    city: "Baltimore",
    state: "Maryland",
    storeAreas: mockDeviceCountByStoreArea
  },
  {
    storesId: 6,
    storeName: "Store 6",
    deviceCount: "10",
    city: "Baltimore",
    state: "Maryland",
    storeAreas: mockDeviceCountByStoreArea
  },
  {
    storesId: 7,
    storeName: "Store 7",
    deviceCount: "10",
    city: "Baltimore",
    state: "Maryland",
    storeAreas: mockDeviceCountByStoreArea
  },
  {
    storesId: 8,
    storeName: "Store 8",
    deviceCount: "10",
    city: "Baltimore",
    state: "Maryland",
    storeAreas: mockDeviceCountByStoreArea
  },
  {
    storesId: 9,
    storeName: "Store 9",
    deviceCount: "10",
    city: "Baltimore",
    state: "Maryland",
    storeAreas: mockDeviceCountByStoreArea
  },
  {
    storesId: 10,
    storeName: "Store 10",
    deviceCount: "10",
    city: "Baltimore",
    state: "Maryland",
    storeAreas: mockDeviceCountByStoreArea
  }
]

export type CreateCampaignFileUploadOptions = object & {
  assetUrl: string
  assetType: string
}

export type CampaignInfo = {
  deviceCount: number | null
  duration: number | null
  activeStoreCount: number | null
  storeAreaName: string | null
}

export type SupportedAssetType = "video/mp4" | "image/png" | "image/jpeg"

export const CampaignTypes = [
  {
    id: 1,
    name: "Paid Ad"
  },
  {
    id: 2,
    name: "Popspots Ad"
  },
  {
    id: 3,
    name: "Retail Filler"
  },
  {
    id: 4,
    name: "Popspots Filler"
  }
]

export const mockUpdatedCampaignStores: NewCampaignStore[] = [
  {
    id: 1,
    name: "Store 1",
    company_id: 123,
    installed: true,
    banner: {
      id: 1,
      name: "Banner 1"
    },
    address: {
      street: "123 Main St",
      city: "Baltimore",
      state: "Maryland",
      postal_code: "12345"
    },
    properties: [
      {
        property_id: 1,
        property_name: "Property 1",
        value_id: "1",
        value_name: "Value 1"
      }
    ]
  },
  {
    id: 2,
    name: "Store 2",
    company_id: 456,
    installed: false,
    banner: {
      id: 2,
      name: "Banner 2"
    },
    address: {
      street: "456 Elm St",
      city: "Baltimore",
      state: "Maryland",
      postal_code: "67890"
    },
    properties: [
      {
        property_id: 2,
        property_name: "Property 2",
        value_id: "2",
        value_name: "Value 2"
      }
    ]
  },
  {
    id: 3,
    name: "Store 3",
    company_id: 789,
    installed: true,
    banner: {
      id: 3,
      name: "Banner 3"
    },
    address: {
      street: "789 Oak St",
      city: "Baltimore",
      state: "Maryland",
      postal_code: "11223"
    },
    properties: [
      {
        property_id: 3,
        property_name: "Property 3",
        value_id: "3",
        value_name: "Value 3"
      }
    ]
  },
  {
    id: 4,
    name: "Store 4",
    company_id: 101,
    installed: true,
    banner: {
      id: 4,
      name: "Banner 4"
    },
    address: {
      street: "101 Pine St",
      city: "Baltimore",
      state: "Maryland",
      postal_code: "33445"
    },
    properties: [
      {
        property_id: 4,
        property_name: "Property 4",
        value_id: "4",
        value_name: "Value 4"
      }
    ]
  },
  {
    id: 5,
    name: "Store 5",
    company_id: 202,
    installed: false,
    banner: {
      id: 5,
      name: "Banner 5"
    },
    address: {
      street: "202 Maple St",
      city: "Baltimore",
      state: "Maryland",
      postal_code: "55667"
    },
    properties: [
      {
        property_id: 5,
        property_name: "Property 5",
        value_id: "5",
        value_name: "Value 5"
      }
    ]
  },
  {
    id: 6,
    name: "Store 6",
    company_id: 303,
    installed: true,
    banner: {
      id: 6,
      name: "Banner 6"
    },
    address: {
      street: "303 Cedar St",
      city: "Baltimore",
      state: "Maryland",
      postal_code: "77889"
    },
    properties: [
      {
        property_id: 6,
        property_name: "Property 6",
        value_id: "6",
        value_name: "Value 6"
      }
    ]
  },
  {
    id: 7,
    name: "Store 7",
    company_id: 404,
    installed: false,
    banner: {
      id: 7,
      name: "Banner 7"
    },
    address: {
      street: "404 Birch St",
      city: "Baltimore",
      state: "Maryland",
      postal_code: "99000"
    },
    properties: [
      {
        property_id: 7,
        property_name: "Property 7",
        value_id: "7",
        value_name: "Value 7"
      }
    ]
  },
  {
    id: 8,
    name: "Store 8",
    company_id: 505,
    installed: true,
    banner: {
      id: 8,
      name: "Banner 8"
    },
    address: {
      street: "505 Spruce St",
      city: "Baltimore",
      state: "Maryland",
      postal_code: "11122"
    },
    properties: [
      {
        property_id: 8,
        property_name: "Property 8",
        value_id: "8",
        value_name: "Value 8"
      }
    ]
  },
  {
    id: 9,
    name: "Store 9",
    company_id: 606,
    installed: false,
    banner: {
      id: 9,
      name: "Banner 9"
    },
    address: {
      street: "606 Willow St",
      city: "Baltimore",
      state: "Maryland",
      postal_code: "33344"
    },
    properties: [
      {
        property_id: 9,
        property_name: "Property 9",
        value_id: "9",
        value_name: "Value 9"
      }
    ]
  },
  {
    id: 10,
    name: "Store 10",
    company_id: 707,
    installed: true,
    banner: {
      id: 10,
      name: "Banner 10"
    },
    address: {
      street: "707 Fir St",
      city: "Baltimore",
      state: "Maryland",
      postal_code: "55566"
    },
    properties: [
      {
        property_id: 10,
        property_name: "Property 10",
        value_id: "10",
        value_name: "Value 10"
      }
    ]
  }
]

export const CampaignFormSchema = Yup.object().shape({
  name: Yup.string().trim().required("Name is Required"),
  storeSearchTerm: Yup.string().optional().default(""),
  isNoEndDate: Yup.boolean().default(false),
  file: Yup.object()
    .shape({
      assetUrl: Yup.string().required(),
      assetType: Yup.string().required(),
      asset: Yup.mixed(),
      duration: Yup.number().optional().default(6),
      assetId: Yup.number().nullable().default(null)
    })
    .required("File is required"),
  selectedZones: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string().required(),
        orientation: Yup.string()
          .oneOf(["landscape", "portrait"] as const)
          .required(),
        ratio: Yup.string().oneOf(["16:9", "9:16"]).required(),
        max_creative_duration_ms: Yup.number().required()
      })
    )
    .optional()
    .default([]),
  selectedZonePlacements: Yup.array()
    .of(
      Yup.object().shape({
        zoneId: Yup.number().required(),
        placementIds: Yup.array().of(Yup.number()).required()
      })
    )
    .optional()
    .default([]),
  startDate: (Yup.mixed() as Yup.Schema<Dayjs | null>)
    .nullable()
    .test("is-date", "Start date is required", (value: Dayjs | null) => {
      return value === null || (dayjs.isDayjs(value) && value.isValid())
    })
    .required("Start date is required"),
  endDate: (Yup.mixed() as Yup.Schema<Dayjs | null>)
    .nullable()
    .test("is-date", "End date is required", (value: Dayjs | null) => {
      return value === null || (dayjs.isDayjs(value) && value.isValid())
    })
    .required("End date is required"),
  activeStores: Yup.array()
    .of(Yup.number().required())
    .min(1, "Select at least one store for this campaign to play."),
  selectedPropertyValues: Yup.array().of(Yup.number()).optional(),
  durationList: Yup.array().of(Yup.number()).default([]).optional(),
  selectedBanners: Yup.array().of(Yup.number()).default([]).optional(),
  selectedStates: Yup.array().of(Yup.string()).default([]).optional()
})

export type CampaignFormModel = Yup.InferType<typeof CampaignFormSchema>

export type FieldsType = {
  isTemplate: boolean
  name: string
  aspectRatio: string
  storeAreasId?: number
  duration?: number
}

const maxCreativeDurationMs = 30000

export const MOCK_ZONE_PLACEMENTS: ZonePlacement[] = [
  {
    id: 1,
    name: "Entrance",
    orientation: "portrait",
    ratio: "9:16",
    max_creative_duration_ms: maxCreativeDurationMs,
    placements: [
      {
        id: 1,
        name: "Placement 1"
      }
    ]
  },
  {
    id: 2,
    name: "Front-End",
    orientation: "landscape",
    ratio: "16:9",
    max_creative_duration_ms: maxCreativeDurationMs,
    placements: [
      {
        id: 2,
        name: "Placement 2"
      }
    ]
  },
  {
    id: 3,
    name: "Pharmacy",
    orientation: "landscape",
    ratio: "16:9",
    max_creative_duration_ms: maxCreativeDurationMs,
    placements: [
      {
        id: 3,
        name: "Placement 3"
      }
    ]
  }
]

type CampaignVideo = {
  companiesId: number | null
  createdBy: string | null
  createdTimestamp: number | null
  duration: number | null
  id: number
  isTemplate: boolean
  name: string | null
  status: string | null
  url: string | null
}

export type TargetingGroup = {
  id: number
  name: string
  stores: CampaignStore[]
  isRetailer: boolean
}
export type FileErrorType =
  | "unsupported-file-type"
  | "invalid-video-duration"
  | "invalid-asset-aspect-ratio"
  | "invalid-asset-aspect-ratio-for-store"
  | "invalid-asset-resolution"
