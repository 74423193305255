import React from "react"
import { Box, Checkbox, Typography } from "@mui/material"
import { StoreTargetMapping } from "../../../../models/storetarget"
import { useWatch } from "react-hook-form"

interface Props {
  target: StoreTargetMapping
  selected: boolean
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
}

const StoreMappingRow = ({ target, selected, onChange }: Props) => {
  const selectedButton = useWatch({
    name: "selectedButton"
  })

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns:
          selectedButton === "unmapped"
            ? "1.5fr 1.5fr 1fr"
            : "1.5fr 1.5fr 1fr 1fr",
        padding: "2px 0",
        backgroundColor: selected ? "secondary.contrastText" : "secondary.main"
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          checked={selected}
          size="small"
          sx={{
            color: "text.primary",
            fontSize: "14px",
            "&.Mui-checked": {
              color: "primary.light"
            }
          }}
          onChange={onChange}
        />
        <Typography
          sx={{
            marginLeft: "8px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "41px"
          }}
          title={target.store}
        >
          {target.store}
        </Typography>
      </Box>
      <Typography
        title={target.bannerName}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          padding: "0 12px",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "41px"
        }}
        variant="body2"
      >
        {target.bannerName}
      </Typography>
      <Typography
        title={target.location}
        sx={{
          lineHeight: "41px",
          textAlign: "left",
          padding: "0 12px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: "14px",
          fontWeight: 500
        }}
        variant="body2"
      >
        {target.location}
      </Typography>
      {selectedButton !== "unmapped" && (
        <Typography
          title={target.propertyValue}
          sx={{
            lineHeight: "41px",
            textAlign: "left",
            padding: "0 12px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "14px",
            fontWeight: 500
          }}
          variant="body2"
        >
          {target.propertyValue}
        </Typography>
      )}
    </Box>
  )
}

export default StoreMappingRow
