import React from "react"
import { Box, Typography, Switch } from "@mui/material"
import { Control, Controller, FieldValues } from "react-hook-form"

interface Props {
  control: Control<FieldValues>
  isCampaignEnded: boolean
  handleNoEndDateChange: (checked: boolean) => void
  style?: React.CSSProperties
}

const PromotionNoEndToggle = ({
  control,
  isCampaignEnded,
  handleNoEndDateChange,
  style
}: Props) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        gap: "8px",
        ...style
      }}
    >
      <Controller
        name="isNoEndDate"
        control={control}
        render={({ field }) => (
          <Switch
            {...field}
            disableRipple
            data-testid="isNoEndDate"
            sx={{
              width: "40px",
              height: "20px",
              padding: 0,
              "& .MuiSwitch-switchBase": {
                padding: "3px",
                margin: 0,
                "&.Mui-checked": {
                  transform: "translateX(20px)"
                }
              },
              "& .MuiSwitch-thumb": {
                width: "14px",
                height: "14px",
                color: "secondary.main"
              },
              "& .MuiSwitch-track": {
                borderRadius: "12px",
                backgroundColor: "secondary.light"
              },
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "primary.light"
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "primary.light"
              }
            }}
            checked={field.value}
            disabled={isCampaignEnded}
            onChange={(event) => handleNoEndDateChange(event.target.checked)}
          />
        )}
      />
      <Typography
        variant="body2"
        sx={{ whiteSpace: "nowrap", opacity: isCampaignEnded ? 0.5 : 1 }}
      >
        No End Date
      </Typography>
    </Box>
  )
}

export default PromotionNoEndToggle
