/* eslint-disable indent */
/* eslint-disable react/jsx-no-undef */
import React from "react"
import { Box, Button, Popper, Typography, Chip } from "@mui/material"
import { useController, useFormContext } from "react-hook-form"
import { NewCampaignStore, Status } from "../../../models/campaign"
import { ZonePlacement } from "../../../models/zoneplacement"
import { StoreBanner } from "../../../models/storetarget"
import { matchSorter } from "match-sorter"
import SearchInput from "../../inputs/SearchInput"
import ErrorBanner from "../../banners/ErrorBanner"
import StoreSelectorTable from "../../StoreSelectorTable"
import StoreFilters from "../../StoreFilters"
import ArrowDropDown from "@mui/icons-material/ArrowDropDown"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import CancelIcon from "../../icons/CancelIcon"

interface PropertyValueApiReponse {
  id: number
  name: string
  is_editable: boolean
}

interface PropertyApiResponse {
  id: number
  name: string
  type: string
  values: PropertyValueApiReponse[]
}
interface Props {
  zonePlacementList?: ZonePlacement[]
  campaignStores?: NewCampaignStore[]
  campaignStatus?: Status
  isLoadingStores: boolean
  storePropertyList: PropertyApiResponse[] | undefined
  campaignBannerList?: StoreBanner[]
  campaignStateList?: string[]
  isBannerExpanded: boolean
  isStateExpanded: boolean
}

const TargetSelector = ({
  zonePlacementList,
  campaignStores,
  campaignStatus,
  isLoadingStores,
  storePropertyList,
  campaignBannerList,
  campaignStateList,
  isBannerExpanded,
  isStateExpanded
}: Props) => {
  const { control, watch, formState, setValue } = useFormContext()
  const { errors } = formState

  if (!zonePlacementList) {
    return null
  }

  const selectedZones = watch("selectedZones") || []
  const selectedZonePlacements = watch("selectedZonePlacements") || []
  const activeStores = watch("activeStores") || []
  const storeSearchTerm = watch("storeSearchTerm") || ""

  const [expandedPanels, setExpandedPanels] = React.useState<string[]>([])
  const [targets, setTargets] = React.useState<NewCampaignStore[]>(
    campaignStores || []
  )
  const [sortTargetsByColumn, setSortTargetsByColumn] = React.useState("Store")
  const [sortTargetsDirection, setSortTargetsDirection] = React.useState<
    "asc" | "desc"
  >("asc")
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const selectedPropertyValues = watch("selectedPropertyValues", [])
  const selectedBanners = watch("selectedBanners", [])
  const selectedStates = watch("selectedStates", [])
  const hasNoSelections =
    selectedZones.length === 0 && selectedZonePlacements.length === 0

  const { field: searchField } = useController({
    name: "storeSearchTerm",
    control
  })

  const { field: activeStoresField } = useController({
    name: "activeStores",
    control
  })

  const selectedTargetCount = React.useMemo(
    () => activeStores?.length,
    [activeStores]
  )

  const isAllTargetsSelected = React.useMemo(() => {
    if (activeStores.length < 1) {
      return false
    }

    return targets.every(
      (store) =>
        activeStores.findIndex(
          (activeStore: number) => activeStore === store.id
        ) !== -1
    )
  }, [activeStores, targets])

  React.useEffect(() => {
    let currentViewData = [...(campaignStores || [])]

    if (storeSearchTerm && storeSearchTerm !== "") {
      currentViewData = matchSorter(currentViewData, storeSearchTerm, {
        keys: ["id", "banner.name", "address.state", "address.city"]
      })
    }

    setTargets(currentViewData)
  }, [campaignStores, storeSearchTerm])

  React.useEffect(() => {
    const initialPanels: string[] = []
    if (isBannerExpanded) {
      initialPanels.push("panel-banner")
    }
    if (isStateExpanded) {
      initialPanels.push("panel-state")
    }
    // Add panels for properties that have selected values
    if (storePropertyList && selectedPropertyValues.length > 0) {
      storePropertyList.forEach((property) => {
        if (
          property.values.some((value) =>
            selectedPropertyValues.includes(value.id)
          )
        ) {
          initialPanels.push(`panel-${property.id}`)
        }
      })
    }
    setExpandedPanels(initialPanels)
  }, [isBannerExpanded, isStateExpanded])

  const isTargetSelected = React.useCallback(
    (targetId: number) =>
      activeStores.some((store: number) => store === targetId),
    [activeStores]
  )

  const onSelectAllTargets = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        const allTargetIds = targets.map((target) => target.id)
        activeStoresField.onChange(allTargetIds)
      } else {
        activeStoresField.onChange([])
      }
    },
    [activeStoresField, targets]
  )

  const sortedTargets = React.useMemo(() => {
    const getValue = (target: NewCampaignStore) => {
      switch (sortTargetsByColumn) {
        case "Banner":
          return target.banner?.name ?? ""
        case "Store":
          return target.id.toString()
        case "City,State":
          return `${target.address?.city}, ${target.address?.state}`.toLowerCase()
        default:
          return ""
      }
    }

    const sorter = (a: NewCampaignStore, b: NewCampaignStore) => {
      const valueA = getValue(a)
      const valueB = getValue(b)

      if (!isNaN(Number(valueA)) && !isNaN(Number(valueB))) {
        return sortTargetsDirection === "asc"
          ? Number(valueA) - Number(valueB)
          : Number(valueB) - Number(valueA)
      }

      if (sortTargetsDirection === "asc") {
        return valueA.toString().localeCompare(valueB.toString())
      } else {
        return valueB.toString().localeCompare(valueA.toString())
      }
    }

    return [...targets].sort(sorter)
  }, [targets, sortTargetsByColumn, sortTargetsDirection])

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedPanels((prev) =>
        isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
      )
    }

  const handleValueChange = (valueId: number) => {
    const newSelectedValues = selectedPropertyValues.includes(valueId)
      ? selectedPropertyValues.filter((id: number) => id !== valueId)
      : [...selectedPropertyValues, valueId]
    setValue("selectedPropertyValues", newSelectedValues)
  }

  const handleBannerChange = (bannerId: number) => {
    const newSelectedBanners = selectedBanners.includes(bannerId)
      ? selectedBanners.filter((id: number) => id !== bannerId)
      : [...selectedBanners, bannerId]
    setValue("selectedBanners", newSelectedBanners)
  }

  const handleStateChange = (state: string) => {
    const newSelectedStates = selectedStates.includes(state)
      ? selectedStates.filter((s: string) => s !== state)
      : [...selectedStates, state]
    setValue("selectedStates", newSelectedStates)
  }

  const handleSortChange = (columnName: string) => {
    if (columnName === sortTargetsByColumn) {
      setSortTargetsDirection(sortTargetsDirection === "asc" ? "desc" : "asc")
    } else {
      setSortTargetsByColumn(columnName)
      setSortTargetsDirection("asc")
    }
  }

  const onTargetChange = (targetId: number) => {
    const index = activeStores.findIndex((store: number) => store === targetId)
    if (index === -1) {
      activeStoresField.onChange([...activeStores, targetId])
    } else {
      activeStoresField.onChange(
        activeStores.filter((store: number) => store !== targetId)
      )
    }
  }

  const isCampaignEnded = campaignStatus === "Ended"

  const handleBannerClear = (bannerId: number) => {
    const newSelectedBanners = selectedBanners.filter(
      (id: number) => id !== bannerId
    )
    setValue("selectedBanners", newSelectedBanners)
  }

  const handleStateClear = (state: string) => {
    const newSelectedStates = selectedStates.filter((s: string) => s !== state)
    setValue("selectedStates", newSelectedStates)
  }

  const handlePropertyClear = (valueId: number) => {
    const newSelectedValues = selectedPropertyValues.filter(
      (id: number) => id !== valueId
    )
    setValue("selectedPropertyValues", newSelectedValues)
  }

  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "30px",
        display: "flex",
        flexDirection: "column",
        gap: "24px"
      }}
      data-cy="target-selector"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            opacity: isCampaignEnded ? 0.5 : 1
          }}
          fontSize={18}
        >
          Stores
        </Typography>
        {!isCampaignEnded && (
          <Box sx={{ display: "flex", gap: "16px" }}>
            <Button
              id="filter-button"
              aria-controls={anchorEl ? "filter-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={anchorEl ? "true" : undefined}
              endIcon={<ArrowDropDown />}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              disabled={hasNoSelections}
              sx={{
                width: "240px",
                border: "1px solid",
                borderRadius: "6px",
                borderColor: "secondary.light",
                color: "text.primary",
                justifyContent: "space-between"
              }}
            >
              Filters
            </Button>
            <Popper
              id="filter-menu"
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              placement="bottom-start"
              sx={{ zIndex: 1300 }}
            >
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <Box
                  sx={{
                    width: "240px",
                    maxHeight: "400px",
                    borderRadius: "6px",
                    marginTop: "1px",
                    overflow: "auto",
                    border: "1px solid",
                    borderColor: "secondary.light",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
                  }}
                >
                  <StoreFilters
                    campaignBannerList={campaignBannerList}
                    campaignStateList={campaignStateList}
                    storePropertyList={storePropertyList}
                    expandedPanels={expandedPanels}
                    handleBannerChange={handleBannerChange}
                    handleStateChange={handleStateChange}
                    handleValueChange={handleValueChange}
                    handleAccordionChange={handleAccordionChange}
                  />
                </Box>
              </ClickAwayListener>
            </Popper>
            <SearchInput
              searchInput={searchField.value}
              onChange={(e) => {
                const value = e.target.value
                if (value.trim() !== "") {
                  searchField.onChange(value)
                } else {
                  searchField.onChange("")
                }
              }}
              onClear={() => searchField.onChange("")}
              width="260px"
              disabled={hasNoSelections}
            />
          </Box>
        )}
      </Box>
      {(selectedBanners.length > 0 ||
        selectedStates.length > 0 ||
        selectedPropertyValues.length > 0 ||
        storeSearchTerm.trim() !== "") && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "8px",
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              flex: 1,
              overflowX: "auto",
              overflowY: "hidden",
              flexWrap: "nowrap",
              "&::-webkit-scrollbar": {
                height: "4px"
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "secondary.light",
                borderRadius: "4px"
              },
              alignItems: "center"
            }}
          >
            {selectedBanners.map((bannerId: number) => {
              const banner = campaignBannerList?.find((b) => b.id === bannerId)
              if (!banner) return null

              return (
                <Chip
                  key={bannerId}
                  label={`Banner: ${banner.name}`}
                  deleteIcon={<CancelIcon />}
                  onDelete={() => handleBannerClear(bannerId)}
                  size="small"
                  sx={{
                    borderRadius: "360px",
                    paddingX: "5px",
                    backgroundColor: "hint.light",
                    color: "primary.light",
                    "& .MuiChip-deleteIcon": {
                      height: "10px",
                      width: "10px",
                      color: "primary.light"
                    }
                  }}
                />
              )
            })}
            {selectedStates.map((state: string) => (
              <Chip
                key={state}
                label={`State: ${state}`}
                deleteIcon={<CancelIcon />}
                onDelete={() => handleStateClear(state)}
                size="small"
                sx={{
                  borderRadius: "360px",
                  backgroundColor: "hint.light",
                  color: "primary.light",
                  paddingX: "5px",
                  "& .MuiChip-deleteIcon": {
                    height: "10px",
                    width: "10px",
                    color: "primary.light"
                  }
                }}
              />
            ))}
            {selectedPropertyValues.map((valueId: number) => {
              const property = storePropertyList?.find((prop) =>
                prop.values.some((value) => value.id === valueId)
              )
              const value = property?.values.find((v) => v.id === valueId)

              if (!property || !value) return null

              return (
                <Chip
                  key={valueId}
                  label={`${property.name}: ${value.name}`}
                  onDelete={() => handlePropertyClear(valueId)}
                  size="small"
                  deleteIcon={<CancelIcon />}
                  sx={{
                    borderRadius: "360px",
                    paddingX: "5px",
                    backgroundColor: "hint.light",
                    color: "primary.light",
                    "& .MuiChip-deleteIcon": {
                      height: "10px",
                      width: "10px",
                      color: "primary.light"
                    }
                  }}
                />
              )
            })}
          </Box>
          <Button
            variant="text"
            onClick={() => {
              setValue("selectedBanners", [])
              setValue("selectedStates", [])
              setValue("selectedPropertyValues", [])
              setValue("storeSearchTerm", "")
            }}
            sx={{
              color: "primary.light",
              "&:hover": {
                backgroundColor: "transparent"
              }
            }}
          >
            Clear All
          </Button>
        </Box>
      )}
      <Box
        sx={{
          flex: "0 0 auto",
          gap: "19px",
          width: "100%"
        }}
      >
        <Box>
          {errors.activeStores?.message && (
            <ErrorBanner>{errors.activeStores?.message as string}</ErrorBanner>
          )}

          <Box sx={{ display: "flex", gap: "12px" }}>
            <Box
              sx={{
                border: "1px solid ",
                borderColor: "secondary.light",
                borderRadius: "8px",
                overflow: "hidden",
                height: "406px",
                width: "100%"
              }}
            >
              {hasNoSelections ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "16px",
                    height: "100%"
                  }}
                >
                  <img
                    src="/noStoresPlaceholder.png"
                    alt="No selection"
                    style={{
                      height: "120px",
                      width: "120px"
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: "18px",
                      textAlign: "center",
                      color: "secondary.dark",
                      fontWeight: 600,
                      width: "200px"
                    }}
                  >
                    Select at least one zone to view stores
                  </Typography>
                </Box>
              ) : (
                <StoreSelectorTable
                  storeSearchTerm={storeSearchTerm}
                  isLoadingStores={isLoadingStores}
                  isCampaignEnded={isCampaignEnded}
                  sortTargetsByColumn={sortTargetsByColumn}
                  sortTargetsDirection={sortTargetsDirection}
                  onSortChange={handleSortChange}
                  isTargetSelected={isTargetSelected}
                  onTargetChange={onTargetChange}
                  sortedTargets={sortedTargets}
                  onSearchClear={() => searchField.onChange("")}
                  onSelectAllTargets={onSelectAllTargets}
                  isAllTargetsSelected={isAllTargetsSelected}
                />
              )}
            </Box>
          </Box>
          <Box sx={{ padding: "16px 0" }}>
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              {hasNoSelections
                ? "0 of 0 stores selected"
                : `${selectedTargetCount} of ${targets.length} stores selected`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TargetSelector
