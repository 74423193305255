import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const StartIconOutline = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_6967)">
          <mask
            id="mask0_1_6967"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="21"
            height="20"
          >
            <circle
              cx="10.5"
              cy="10"
              r="6.75"
              stroke="#0C0C0D"
              strokeWidth="1.5"
            />
            <path
              d="M6.75 9.99984L9.25 12.4998L14.25 7.9165"
              stroke="#0C0C0D"
              strokeWidth="1.5"
            />
          </mask>
          <g mask="url(#mask0_1_6967)">
            <rect x="0.5" width="20" height="20" fill="currentColor" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1_6967">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default StartIconOutline
