import React from "react"
import { Box, Checkbox, Typography } from "@mui/material"
import { StoreTargetMapping } from "src/models/storetarget"

interface HeaderRowProps {
  filteredTargets: StoreTargetMapping[]
  columns: string[]
  isAllTargetsSelected: boolean
  onSelectAllTargets: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
}

const StoreMappingHeaderRow = ({
  columns,
  isAllTargetsSelected,
  onSelectAllTargets,
  filteredTargets
}: HeaderRowProps) => {
  const getColumnPadding = (column: string) =>
    column === "Store" ? "0 0 0 1px" : "0 12px"
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns:
          columns.length === 3 ? "1.5fr 1.5fr 1fr" : "1.5fr 1.5fr 1fr 1fr",
        padding: "2px 0",
        backgroundColor: "secondary.main",
        fontWeight: "bold"
      }}
    >
      {columns.map((column) => (
        <Box
          key={column}
          sx={{
            display: "flex",
            justifyContent: "start",
            padding: getColumnPadding(column),
            alignItems: "center",
            gap: "8px"
          }}
        >
          {column === "Store" && (
            <Checkbox
              checked={isAllTargetsSelected}
              onChange={onSelectAllTargets}
              disabled={filteredTargets.length === 0}
              size="small"
              sx={{
                color: "text.primary",
                "&.Mui-checked": {
                  color: "primary.light"
                }
              }}
            />
          )}
          <Typography
            sx={{ lineHeight: "32px", fontSize: "12px", fontWeight: 600 }}
            variant="body2"
          >
            {column}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export default StoreMappingHeaderRow
