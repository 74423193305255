import React, { useState, useEffect } from "react"
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  FormControlLabel,
  Typography,
  Radio,
  RadioGroup,
  IconButton
} from "@mui/material"
import { matchSorter } from "match-sorter"
import SearchInput from "../../inputs/SearchInput"
import StoreMappingRow from "./StoreMappingRow"
import StoreMappingHeaderRow from "./StoreMappingHeaderRow"
import NoRowOverlay from "../../promotions/NoRowOverlay"
import {
  useController,
  useFieldArray,
  useFormContext,
  useWatch
} from "react-hook-form"
import { StoreTargetMapping } from "../../../models/storetarget"
import { NewCampaignStore } from "../../../models/campaign"
import ChevronLeft from "../../icons/ChevronLeft"
import ChevronRight from "../../icons/ChevronRight"

interface Props {
  data: NewCampaignStore[]
  isEditMode: boolean
}

interface PropertyItem {
  valueId?: string
  value: string
}

const StoreMappingGrid = ({ data, isEditMode }: Props) => {
  const { control, watch, setValue } = useFormContext()

  const storeSearchTerm = watch("storeSearchTerm")

  const { field: selectedButtonField } = useController({
    name: "selectedButton",
    control,
    defaultValue: "unmapped"
  })

  const notApplicable = useWatch({
    name: "notApplicable"
  })

  const handleButtonClick = (buttonName: string) => {
    selectedButtonField.onChange(buttonName)
  }

  const selectedButton = useWatch({
    control,
    name: "selectedButton"
  })

  const propertyType = useWatch({
    control,
    name: "propertyType"
  })

  const propertyName = useWatch({
    control,
    name: "propertyName"
  })

  const propertyItems: PropertyItem[] = useWatch({
    name: "propertyItems"
  })

  const { fields: StoreTargetMappingFields, replace } = useFieldArray({
    control,
    name: "StoreTargetMappings"
  })

  const [selectedRadioValue, setSelectedRadioValue] = useState(
    propertyType === "multi-select" ? propertyItems[0]?.value : "Yes"
  )

  const StoreTargetMappingsWithoutIds = StoreTargetMappingFields.map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ id, ...rest }) => rest as StoreTargetMapping
  )

  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)

  const checkScrollability = () => {
    const container = document.querySelector(".toggle-button-scroll-container")
    if (container) {
      setCanScrollLeft(container.scrollLeft > 0)
      setCanScrollRight(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      )
    }
  }

  useEffect(() => {
    const container = document.querySelector(".toggle-button-scroll-container")
    if (container) {
      checkScrollability()
      container.addEventListener("scroll", checkScrollability)
      return () => container.removeEventListener("scroll", checkScrollability)
    }
  }, [])

  useEffect(() => {
    if (StoreTargetMappingFields.length === 0) {
      if (!isEditMode) {
        // Create mode: Initialize mappings for the first time
        const initialValues = data.map((target) => ({
          storesId: target.id,
          bannerName: target.banner.name,
          store: target.name,
          location: `${target.address?.city}, ${target.address?.state}`,
          propertyValueId: "", // Start with empty values in create mode
          propertyValue: ""
        }))

        replace(initialValues) // Initialize store mappings in create mode
      } else {
        // Edit mode: Populate the mappings based on the backend response

        const initialValues = data.map((store) => {
          // Ensure we are using the updated propertyItems, not just the backend properties

          const matchingProperty = propertyItems.find(
            (item) =>
              store.properties?.some(
                (property) => item.valueId === property?.value_id.toString()
              )
          )

          const isNotApplicable = store.properties?.some(
            (property) =>
              property?.value_id.toString() === notApplicable?.valueId
          )

          return {
            storesId: store.id,
            bannerName: store.banner.name,
            store: store.name,
            location: `${store.address.city}, ${store.address.state}`,
            propertyValueId:
              matchingProperty?.valueId ||
              (isNotApplicable ? notApplicable?.valueId : ""),
            propertyValue:
              matchingProperty?.value ||
              (isNotApplicable ? notApplicable?.value : "")
          }
        })

        replace(initialValues)
      }
    } else {
      const validPropertyIds = propertyItems.map((item) => item.valueId)

      setValue(
        "StoreTargetMappings",
        StoreTargetMappingsWithoutIds.map((mapping) => {
          if (mapping.propertyValue === "Not Applicable") {
            return mapping // Keep "Not Applicable" unchanged
          }

          const correspondingItem = propertyItems.find(
            (item) => item.valueId === mapping.propertyValueId
          )

          // If the propertyValueId is invalid, reset it
          if (!validPropertyIds.includes(mapping.propertyValueId)) {
            return { ...mapping, propertyValue: "", propertyValueId: "" }
          }

          // If the propertyValueId is valid but the propertyValue is incorrect, update it
          if (
            correspondingItem &&
            correspondingItem.value !== mapping.propertyValue
          ) {
            return { ...mapping, propertyValue: correspondingItem.value }
          }

          return mapping
        })
      )
    }
  }, [isEditMode])

  const filteredTargets = React.useMemo(() => {
    let targetsToFilter = StoreTargetMappingsWithoutIds

    if (selectedButton === "unmapped") {
      targetsToFilter = targetsToFilter.filter(
        (mapping) => !mapping.propertyValue || mapping.propertyValue === ""
      )
    } else if (selectedButton === "notApplicable") {
      targetsToFilter = targetsToFilter.filter(
        (mapping) => mapping.propertyValue === "Not Applicable"
      )
    } else {
      targetsToFilter = targetsToFilter.filter(
        (mapping) => mapping.propertyValue === selectedButton
      )
    }

    if (storeSearchTerm && storeSearchTerm !== "") {
      return matchSorter<StoreTargetMapping>(targetsToFilter, storeSearchTerm, {
        keys: ["storesId", "bannerName", "location"]
      })
    }
    return targetsToFilter
  }, [storeSearchTerm, StoreTargetMappingsWithoutIds, selectedButton])

  const { field: searchField } = useController({
    name: "storeSearchTerm",
    control
  })

  const { field: activeStoresField } = useController({
    name: "activeStores",
    control
  })

  const activeStores: number[] = watch("activeStores", [])

  const selectedTargetCount = React.useMemo(
    () => activeStores?.length,
    [activeStores]
  )

  const isAllTargetsSelected: boolean = React.useMemo(() => {
    if (activeStores.length < 1) {
      return false
    }

    return filteredTargets.every(
      (store) =>
        activeStores.findIndex(
          (activeStore) => activeStore === store.storesId
        ) !== -1
    )
  }, [activeStores, filteredTargets])

  const onSelectAllTargets = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        const allTargetIds = filteredTargets.map((target) => target.storesId)
        activeStoresField.onChange(allTargetIds)
      } else {
        activeStoresField.onChange([])
      }
    },
    [activeStoresField, filteredTargets]
  )

  // const counterLabel: string = React.useMemo(() => {
  //   return `${selectedTargetCount} selected`
  // }, [selectedTargetCount])

  const unmappedCount = React.useMemo(() => {
    return StoreTargetMappingsWithoutIds.filter(
      (target) => target.propertyValue === ""
    ).length
  }, [StoreTargetMappingsWithoutIds])

  const propertyItemCounts = React.useMemo(() => {
    return propertyItems?.reduce(
      (acc, item) => {
        acc[item.value] = StoreTargetMappingsWithoutIds.filter(
          (target) => target.propertyValue === item.value
        ).length
        return acc
      },
      {} as Record<string, number>
    )
  }, [propertyItems, StoreTargetMappingsWithoutIds])

  const notApplicableCount = React.useMemo(() => {
    return StoreTargetMappingsWithoutIds.filter(
      (target) => target.propertyValue === "Not Applicable"
    ).length
  }, [StoreTargetMappingsWithoutIds])

  const columns =
    selectedButton === "unmapped"
      ? ["Store", "Banner", "Location"]
      : ["Store", "Banner", "Location", propertyName]

  const handleSaveMapping = () => {
    const StoreTargetMappingsWithoutIds = StoreTargetMappingFields.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ id, ...rest }) => rest as StoreTargetMapping
    )

    const selectedPropertyItem = propertyItems.find(
      (item) => item.value === selectedRadioValue
    )

    // Step 3: Map over the existing mappings and update `propertyValue` and `propertyValueId`
    const updatedMappings = StoreTargetMappingsWithoutIds.map((mapping) => {
      if (activeStores.includes(mapping.storesId)) {
        return {
          ...mapping,
          propertyValue: selectedRadioValue,
          propertyValueId: selectedPropertyItem?.valueId || null // Assign `valueId` or `null`
        }
      }
      return mapping
    })

    replace(updatedMappings)

    activeStoresField.onChange([])
  }

  const handleScroll = (direction: "left" | "right") => {
    const container = document.querySelector(".toggle-button-scroll-container")
    if (container) {
      const scrollAmount = 200 // Adjust this value as needed
      container.scrollLeft +=
        direction === "left" ? -scrollAmount : scrollAmount
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflowY: "hidden",
        gap: "24px"
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          color: "text.primary",
          borderBottom: "1px solid",
          borderColor: "secondary.light",
          position: "relative",
          alignItems: "center"
        }}
      >
        <Box
          className="toggle-button-scroll-container"
          sx={{
            display: "flex",
            overflowX: "auto",
            position: "relative",
            maxWidth: "calc(100% - 80px)", // Make room for arrows
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
              display: "none"
            }
          }}
        >
          <ToggleButtonGroup
            value={selectedButton}
            exclusive
            onChange={(event, newButton) => {
              if (newButton !== null) {
                handleButtonClick(newButton)
                activeStoresField.onChange([])
              }
            }}
            sx={{
              display: "flex",
              height: "50px",
              gap: "12px",
              "& .MuiTypography-root": {
                textTransform: "capitalize"
              },
              "& .MuiToggleButtonGroup-grouped": {
                border: "none",
                borderRadius: "0",
                marginLeft: "0 !important",
                borderLeft: "none !important",
                width: "max-content",
                height: "50px",
                minWidth: "120px"
              },
              "& .MuiToggleButtonGroup-grouped.Mui-selected": {
                backgroundColor: "transparent",
                borderBottom: "2px solid",
                borderColor: "primary.light",
                "& .MuiTypography-root": {
                  fontWeight: 600,
                  color: "fileInput.dark"
                }
              }
            }}
          >
            <ToggleButton value="unmapped">
              <Typography variant="body2">{`${unmappedCount} Unmapped`}</Typography>
            </ToggleButton>

            {propertyItems?.map((item, index) => (
              <ToggleButton key={index} value={item.value}>
                <Typography variant="body2">{`${
                  propertyItemCounts[item.value] || 0
                } ${item.value}`}</Typography>
              </ToggleButton>
            ))}

            {propertyType === "multi-select" && (
              <ToggleButton value="notApplicable">
                <Typography variant="body2">{`${notApplicableCount} Not Applicable`}</Typography>
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: "4px",
            position: "absolute",
            right: 0
          }}
        >
          <IconButton
            onClick={() => handleScroll("left")}
            disableRipple
            disabled={!canScrollLeft}
            sx={{
              padding: 0,
              borderRadius: "4px",
              height: "24px",
              width: "24px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
              backgroundColor: "secondary.main"
            }}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            onClick={() => handleScroll("right")}
            disableRipple
            disabled={!canScrollRight}
            sx={{
              padding: 0,
              borderRadius: "4px",
              height: "24px",
              width: "24px",
              boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)",
              backgroundColor: "secondary.main"
            }}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "calc(380px + 48px)",
          overflowY: "hidden",
          gap: "24px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "70%",
            height: "100%"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "8px",
              alignItems: "center"
            }}
          >
            <Typography variant="body2">
              <Box component="span" sx={{ fontWeight: 600 }}>
                {selectedTargetCount}
              </Box>
              {` store${selectedTargetCount > 1 ? "s" : ""} selected`}
            </Typography>

            <Box
              sx={{
                display: "flex",
                width: "30%",
                minWidth: "240px"
              }}
            >
              <SearchInput
                searchInput={searchField.value}
                onChange={(e) => {
                  searchField.onChange(e)
                }}
                onClear={() => searchField.onChange("")}
                width="100%"
              />
            </Box>
          </Box>
          <Box
            sx={{
              border: "1px solid",
              borderColor: "primary.contrastText",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              height: "380px",
              overflow: "hidden"
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "secondary.main",
                paddingRight: "12px",
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
                borderBottom: "1px solid",
                borderColor: "primary.contrastText"
              }}
            >
              <StoreMappingHeaderRow
                filteredTargets={filteredTargets}
                columns={columns}
                isAllTargetsSelected={isAllTargetsSelected}
                onSelectAllTargets={onSelectAllTargets}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                gap: "1px",
                backgroundColor: "secondary.light"
              }}
            >
              {filteredTargets.length === 0 ? (
                selectedButton === "unmapped" ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "380px",
                      backgroundColor: "secondary.main"
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2
                      }}
                    >
                      <Box
                        component="img"
                        src="/allStoresAssignedStatus.png"
                        alt="All stores assigned"
                        sx={{ width: 120, height: 120 }}
                      />
                      <Typography
                        variant="h5"
                        sx={{ fontWeight: 600, color: "primary.contrastText" }}
                      >
                        All stores have been assigned.
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <NoRowOverlay
                    searchInput={storeSearchTerm}
                    handleClearInput={() => searchField.onChange("")}
                  />
                )
              ) : (
                filteredTargets.map((target) => (
                  <StoreMappingRow
                    key={target.storesId}
                    target={target}
                    selected={activeStores.includes(target.storesId)}
                    onChange={() => {
                      const index = activeStores.findIndex(
                        (store: number) => store === target.storesId
                      )
                      if (index === -1) {
                        activeStoresField.onChange([
                          ...activeStores,
                          target.storesId
                        ])
                      } else {
                        activeStoresField.onChange(
                          activeStores.filter(
                            (store) => store !== target.storesId
                          )
                        )
                      }
                    }}
                  />
                ))
              )}
            </Box>
          </Box>
          <Typography variant="body2" sx={{ mt: 1 }}>
            Total stores: {filteredTargets.length}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "30%",
            display: "flex",
            flexDirection: "column",
            border: "1px solid",
            maxHeight: "432px",
            height: "fit-content",
            borderColor: "primary.contrastText",
            backgroundColor: "secondary.main",
            borderRadius: "8px"
          }}
        >
          <Box sx={{ padding: "0 12px", height: "36px" }}>
            <Typography
              variant="body2"
              sx={{ fontWeight: "700", lineHeight: "36px" }}
            >
              {propertyName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              padding: "4px",
              borderTop: "1px solid",
              borderColor: "primary.contrastText"
            }}
          >
            <Box>
              <RadioGroup
                key={propertyType}
                value={selectedRadioValue}
                onChange={(e) => setSelectedRadioValue(e.target.value)}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "8px 16px",
                  "& .MuiRadio-root": {
                    color: "secondary.dark",
                    height: "32px"
                  },
                  "& .Mui-checked": {
                    color: "primary.dark"
                  },
                  "& .MuiTypography-root": {
                    fontSize: "14px"
                  }
                }}
              >
                {propertyType === "boolean" ? (
                  propertyItems &&
                  propertyItems.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      value={item.value}
                      control={<Radio />}
                      label={item.value}
                    />
                  ))
                ) : (
                  <>
                    {propertyItems &&
                      propertyItems.map((item, index) => (
                        <FormControlLabel
                          key={index}
                          value={item.value}
                          control={<Radio />}
                          label={item.value}
                        />
                      ))}

                    <FormControlLabel
                      value="Not Applicable"
                      control={<Radio />}
                      label="Not Applicable"
                    />
                  </>
                )}
              </RadioGroup>
            </Box>
            <Box sx={{ padding: "12px" }}>
              <Button
                variant="contained"
                disabled={filteredTargets.length === 0}
                onClick={handleSaveMapping}
                sx={{ width: "100%" }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default StoreMappingGrid
