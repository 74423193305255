import React from "react"
import { Box, Button, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

const EmptyState = () => {
  const navigate = useNavigate()

  return (
    <Box
      display="flex"
      gap="32px"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-around"
      sx={{
        textAlign: "center",
        height: "100%"
      }}
    >
      <Box
        display="flex"
        gap="0px"
        flexDirection="column"
        alignItems="center"
        sx={{
          textAlign: "center"
        }}
      >
        <Typography variant="h4">Get the word out, digitally</Typography>
        <Typography
          variant="body1"
          sx={{
            width: "427px"
          }}
        >
          Have a message for your shoppers? Reach them directly with your
          in-store digital network.
        </Typography>
        <Button
          variant="contained"
          sx={{
            boxShadow: "none",
            marginTop: "32px"
          }}
          onClick={() => {
            navigate("/promotions/create")
          }}
        >
          Start Marketing
        </Button>
      </Box>
    </Box>
  )
}

export default EmptyState
