/* eslint-disable indent */
import React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { isEqual } from "lodash"
import { Box, Link, Typography } from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import * as amplitude from "@amplitude/analytics-browser"
import PQueue from "p-queue"
import { FormProvider, useForm } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"
import { gql, useMutation, useQuery } from "urql"
import debounce from "lodash/debounce"
import {
  Campaign,
  NewCampaignStore,
  CampaignFormModel,
  CampaignFormSchema,
  FieldsType,
  FileErrorType,
  Status
} from "../../../models/campaign"

import { ZonePlacement } from "../../../models/zoneplacement"
import {
  getUserId,
  convertToZonePlacements,
  extractZonePlacementIdsForSubmission,
  getMaxDurationZoneId,
  getZoneRatio
} from "../../../utils"
import { http } from "../../App"
import DiscardBanner from "../../banners/DiscardBanner"
import ErrorBanner from "../../banners/ErrorBanner"
import SuccessChangeBanner from "../../banners/SuccessChangeBanner"
import WarningBanner from "../../banners/WarningBanner"
import PromotionCreative from "../../promotions/PromotionCreative"
import CampaignForm from "../../promotions/PromotionForm"
import ConfirmationModal from "../../promotions/ConfirmationModal"
import {
  AssetAspectError,
  AssetAspectWithStoreError,
  AssetResolutionError,
  AssetTypeError,
  VideoDurationError
} from "../../promotions/ErrorMessage"
import ZonePlacementSelector from "../../promotions/ZonePlacementSelector"
import TargetSelector from "../../promotions/TargetSelector"
import Loader from "../../Loader"
import NavBar from "../../navbar"
import EditCampaignNavBar from "../../EditPromotionNavBar"
import { StoreBanner } from "../../../models/storetarget"
import InfoOutlineIcon from "../../icons/InfoOutlineIcon"

type FileErrorProps = {
  name: string
}

type FileError = {
  type: FileErrorType
  fileName: string
}

type UploadResponseState = {
  id: number
  duration: number
} | null

const errorComponentMap: Record<
  FileErrorType,
  React.ComponentType<FileErrorProps>
> = {
  "unsupported-file-type": AssetTypeError,
  "invalid-video-duration": VideoDurationError,
  "invalid-asset-aspect-ratio": AssetAspectError,
  "invalid-asset-aspect-ratio-for-store": AssetAspectWithStoreError,
  "invalid-asset-resolution": AssetResolutionError
}

type GetCampaignQuery = {
  campaignDetails: Campaign
}

const DATE_FORMAT = "YYYY-MM-DD"

const UPLOAD_CAMPAIGN_VIDEO_MUTATION = gql`
  mutation UploadCampaignVideo($fields: CreateVideoInput!, $file: Upload!) {
    uploadCampaignVideo(fields: $fields, file: $file) {
      id
      duration
    }
  }
`
const SAVE_CAMPAIGN_MUTATION = gql`
  mutation saveCampaign($fields: CampaignInput!) {
    saveCampaignDetails(fields: $fields) {
      id
      status
      isGlobal
      name
      campaignTypesId
      campaignCategoriesId
      videosId
      tmpVideo
      duration
      activeStores {
        name
        id
        impressions
      }
      draftStores {
        name
        id
      }
    }
  }
`

const STORE_FRAGMENT = gql`
  fragment StoreArgs on Store {
    storeId: id
    name
    deviceCount
    state
    city
    county
    address
  }
`

const GET_CAMPAIGN_VIDEO_URL = gql`
  query getVideoUrl($key: String!) {
    s3Url(key: $key)
  }
`

const GET_BANNERS = gql`
  query getBanners {
    banners {
      id
      name
      stores {
        ...StoreArgs
      }
    }
  }
  ${STORE_FRAGMENT}
`

const CURRENT_USER_QUERY = gql`
  query getUser($id: Int!) {
    userDetails(id: $id) {
      companiesId
      email
      id
    }
  }
`

const GET_CAMPAIGN_QUERY = gql`
  query campaignDetails($id: Int!) {
    campaignDetails(id: $id) {
      id
      campaignTypesId
      companiesId
      isGlobal
      duration
      endDate
      startDate
      status
      name
      impressions
      tmpVideo
      tmpVideoDuration
      creativeAspectRatio
      targetImpressions
      isEvergreen
      video {
        id
        name
        url
        duration
      }
      activeStores {
        id
        name
      }
      storeAreasId
    }
  }
`

interface Props {
  onDidSuccessfullyPublishCampaign: (campaign: Campaign) => void
  onDidSuccessfullyEndCampaign: () => void
  campaignId?: number
  campaignStatus?: Status
  campaignZonePlacementList?: ZonePlacement[]
  campaignStorePropertyList?: PropertyApiResponse[]
  campaignBannerList?: StoreBanner[]
  campaignStateList?: string[]
}

interface PropertyValueApiReponse {
  id: number
  name: string
  is_editable: boolean
}

interface PropertyApiResponse {
  id: number
  name: string
  type: string
  values: PropertyValueApiReponse[]
}

interface Params {
  "zone_ids[]"?: number[]
  "property_values[]"?: number[]
  "placement_ids[]"?: number[]
  "banner_ids[]"?: number[]
  "states[]"?: string[]
}

interface PropertyValueApiReponse {
  id: number
  name: string
  is_editable: boolean
}

interface PropertyApiResponse {
  id: number
  name: string
  type: string
  values: PropertyValueApiReponse[]
}

interface InitialFormValues {
  name: string
  startDate?: Dayjs | null
  endDate?: Dayjs | null
  selectedZones: ZonePlacement[]
  activeStores: number[]
  isNoEndDate: boolean
  selectedPropertyValues: number[]
  selectedZonePlacements: { zoneId: number; placementIds: number[] }[]
  selectedBanners: number[]
  selectedStates: string[]
}

const uploadFileQueue = new PQueue({ concurrency: 1 })

const EditPromotion = ({
  campaignId,
  campaignStatus,
  onDidSuccessfullyPublishCampaign,
  campaignZonePlacementList,
  campaignStorePropertyList,
  campaignBannerList,
  campaignStateList,
  onDidSuccessfullyEndCampaign
}: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const path = location?.pathname
  const [openDiscardModal, setOpenDiscardModal] = React.useState(false)
  const [fileError, setFileError] = React.useState<FileError | null>(null)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [isLoadingStores, setIsLoadingStores] = React.useState(false)
  const [isBannerExpanded, setIsBannerExpanded] = React.useState(false)
  const [isStateExpanded, setIsStateExpanded] = React.useState(false)
  const [stores, setStores] = React.useState<NewCampaignStore[]>()
  const [targetingSubmissionError, setTargetingSubmissionError] =
    React.useState(false)
  const [storeLoadingError, setStoreLoadingError] =
    React.useState<Error | null>(null)
  const [failedToUploadAsset, setFailedToUploadAsset] = React.useState(false)
  const [failedToPublish, setFailedToPublish] = React.useState(false)
  const [discardUnSavedChange, setDiscardUnSavedChange] = React.useState(false)
  const [publishChange, setPublishChange] = React.useState(false)
  const [fileUploadResponse, setFileUploadResponse] =
    React.useState<UploadResponseState>(null)
  const [pendingUploads, setPendingUploads] = React.useState(false)
  const [isInitializing, setIsInitializing] = React.useState(true)

  const [showBoxShadow, setShowBoxShadow] = React.useState(false)
  const [isEndingCampaign, setIsEndingCampaign] = React.useState(false)
  const [showEndNowModal, setShowEndNowModal] = React.useState(false)
  const [showScheduleModal, setShowScheduleModal] = React.useState(false)

  const initializedCampaignId = React.useRef<number>()

  const formMethods = useForm<CampaignFormModel>({
    resolver: yupResolver(CampaignFormSchema),
    mode: "all",
    defaultValues: {
      name: "",
      startDate: null,
      endDate: null,
      selectedZones: [],
      storeSearchTerm: "",
      activeStores: [],
      isNoEndDate: false,
      file: {},
      selectedPropertyValues: [],
      durationList: [],
      selectedZonePlacements: [],
      selectedBanners: [],
      selectedStates: []
    }
  })

  const { setError, setValue, formState, watch, resetField, reset } =
    formMethods

  const selectedZones = watch("selectedZones") || []
  const selectedPropertyValues = watch("selectedPropertyValues")
  const selectedZonePlacements = watch("selectedZonePlacements") || []

  const selectedBanners = watch("selectedBanners") || []
  const selectedStates = watch("selectedStates") || []
  const activeStores = watch("activeStores") || []
  const assetId = watch("file.assetId")
  const assetFile = watch("file")
  const name = watch("name")
  const startDate = watch("startDate")
  const endDate = watch("endDate")
  const isNoEndDate = watch("isNoEndDate")

  const initialValues = React.useRef<InitialFormValues>({
    name: "",
    startDate: undefined,
    endDate: undefined,
    selectedZones: [],
    activeStores: [],
    isNoEndDate: false,
    selectedPropertyValues: [],
    selectedZonePlacements: [],
    selectedBanners: [],
    selectedStates: []
  })

  const userId = getUserId()
  const [{ data: currentUser }] = useQuery({
    query: CURRENT_USER_QUERY,
    pause: !userId,
    variables: {
      id: userId
    }
  })

  const [
    {
      data: campaignData,
      fetching: campaignFetching,
      error: campaignFetchError
    }
  ] = useQuery<GetCampaignQuery>({
    query: GET_CAMPAIGN_QUERY,
    pause: !campaignId,
    variables: {
      id: campaignId
    },
    requestPolicy: "network-only"
  })

  const [
    { data: bannersData, fetching: bannersFetching, error: bannersFetchError }
  ] = useQuery({
    query: GET_BANNERS
  })

  const fetchStores = (
    zoneIds: number[],
    zonePlacementIds: number[] = [],
    bannerIds: number[] = [],
    states: string[] = [],
    propertyValues: number[] = []
  ) => {
    const params: Params = {
      "zone_ids[]": zoneIds,
      "placement_ids[]": zonePlacementIds,
      "banner_ids[]": bannerIds,
      "states[]": states,
      "property_values[]": propertyValues
    }

    return http.get(`${process.env.REACT_APP_REST_API_PATH}/stores`, {
      params,
      withCredentials: true
    })
  }

  const fetchSelectedZones = async () => {
    if (!campaignId || !campaignZonePlacementList) return

    try {
      const response = await http.get(
        `${process.env.REACT_APP_REST_API_PATH}/campaigns/${campaignId}/targeting_details`,
        {
          withCredentials: true
        }
      )

      const { zone_placements, filters, stores } = response.data
      const { zone_ids, placement_ids } = zone_placements
      const { property_values, banner_ids, states } = filters

      if (
        (!zone_ids || zone_ids.length === 0) &&
        (!placement_ids || placement_ids.length === 0)
      ) {
        setTargetingSubmissionError(true)
      }

      reset({
        ...formMethods.getValues(),
        activeStores: stores.map((store: { id: number }) => store.id)
      })

      // Handle selected zones
      const selectedZones = campaignZonePlacementList.filter((zone) =>
        zone_ids.includes(zone.id)
      )

      // Convert API response to zone placements using utility function
      const selectedZonePlacements = convertToZonePlacements(
        campaignZonePlacementList,
        zone_ids,
        placement_ids
      )

      const selectedPropertyValueIds = property_values.flatMap(
        (property: PropertyApiResponse) =>
          property.values.map((value: PropertyValueApiReponse) => value.id)
      )

      if (property_values.length > 0) {
        reset({
          ...formMethods.getValues(),
          selectedPropertyValues: selectedPropertyValueIds
        })
      }

      if (banner_ids.length > 0) {
        setIsBannerExpanded(true)
        reset({
          ...formMethods.getValues(),
          selectedBanners: banner_ids
        })
      }

      if (states.length > 0) {
        setIsStateExpanded(true)
        reset({
          ...formMethods.getValues(),
          selectedStates: states
        })
      }

      reset({
        ...formMethods.getValues(),
        selectedZones,
        selectedZonePlacements
      })
      initialValues.current = {
        ...initialValues.current,
        selectedZones,
        activeStores: stores.map((store: { id: number }) => store.id),
        selectedPropertyValues: selectedPropertyValueIds,
        selectedZonePlacements,
        selectedBanners: banner_ids,
        selectedStates: states
      }
    } catch (error) {
      console.error("Failed to fetch selected zones:", error)
      setTargetingSubmissionError(true)
    }
  }

  React.useEffect(() => {
    if (campaignZonePlacementList) {
      fetchSelectedZones()
    }
  }, [campaignZonePlacementList])

  React.useEffect(() => {
    const handleScroll = () => {
      const dialogContent = document.querySelector(
        ".MuiDialog-root .MuiPaper-root"
      )
      if (dialogContent) {
        const hasScrolled = dialogContent.scrollTop > 24
        setShowBoxShadow(hasScrolled)
      }
    }

    const timer = setTimeout(() => {
      const dialogContent = document.querySelector(
        ".MuiDialog-root .MuiPaper-root"
      )
      if (dialogContent) {
        dialogContent.addEventListener("scroll", handleScroll)
      }
    }, 100)

    return () => {
      const dialogContent = document.querySelector(
        ".MuiDialog-root .MuiPaper-root"
      )
      if (dialogContent) {
        dialogContent.removeEventListener("scroll", handleScroll)
      }
      clearTimeout(timer)
    }
  }, [])

  const [, uploadCampaignVideo] = useMutation(UPLOAD_CAMPAIGN_VIDEO_MUTATION)

  const [, saveCampaign] = useMutation(SAVE_CAMPAIGN_MUTATION)

  const compareNameFn = () => {
    return (itemA: NewCampaignStore, itemB: NewCampaignStore) =>
      itemA.name < itemB.name ? -1 : 1
  }

  React.useEffect(() => {
    if (!campaignId) {
      setIsInitializing(false)
      return
    }

    if (targetingSubmissionError && campaignFetchError) {
      setIsInitializing(false)
      return
    }

    // Edit mode - check all required data
    const isDataLoading =
      campaignFetching ||
      bannersFetching ||
      campaignStorePropertyList === undefined ||
      campaignBannerList === undefined ||
      campaignStateList === undefined

    const hasErrors =
      campaignFetchError !== undefined || bannersFetchError !== undefined

    const hasRequiredData =
      campaignData?.campaignDetails &&
      (selectedZones?.length || selectedZonePlacements.length)

    if (
      (!isDataLoading &&
        !hasErrors &&
        hasRequiredData &&
        initializedCampaignId.current !== campaignId) ||
      (targetingSubmissionError && campaignData?.campaignDetails)
    ) {
      const { campaignDetails } = campaignData

      const currentFile = formMethods.getValues("file") || {}
      const shouldInitializeFile =
        (campaignDetails.video?.id || campaignDetails.tmpVideo) &&
        formState.defaultValues?.file?.assetId ===
          formMethods.getValues("file.assetId")

      reset({
        ...formMethods.getValues(),
        name: campaignDetails.name,
        endDate: dayjs(Number(campaignDetails.endDate)),
        isNoEndDate: campaignDetails.isEvergreen,
        startDate: dayjs(Number(campaignDetails.startDate)),
        ...(shouldInitializeFile && {
          file: {
            ...currentFile,
            assetId:
              campaignDetails.tmpVideo ?? campaignDetails.video?.id ?? -1,
            duration: campaignDetails.duration / 1000
          }
        })
      })

      initialValues.current = {
        ...initialValues.current,
        name: campaignDetails.name,
        startDate: dayjs(Number(campaignDetails.startDate)),
        endDate: dayjs(Number(campaignDetails.endDate)),
        isNoEndDate: Boolean(campaignDetails.isEvergreen)
      }

      setIsInitializing(false)

      initializedCampaignId.current = campaignId
    }
  }, [
    campaignData?.campaignDetails,
    campaignZonePlacementList,
    campaignFetching,
    campaignFetchError,
    bannersFetchError,
    targetingSubmissionError,
    bannersFetching,
    selectedZones?.length,
    selectedZonePlacements.length,
    campaignId
  ])

  const [{ data: campaignVideo }] = useQuery({
    query: GET_CAMPAIGN_VIDEO_URL,
    pause: !assetId,
    variables: {
      key: `videos/${assetId}/${assetId}.timg1.png`
    },
    requestPolicy: "network-only"
  })

  React.useEffect(() => {
    if (campaignVideo?.s3Url) {
      if (
        formState.defaultValues?.file?.assetId ===
        formMethods.getValues("file.assetId")
      ) {
        resetField("file", {
          defaultValue: {
            ...formMethods.getValues("file"),
            assetUrl: campaignVideo.s3Url,
            assetType: "image/png"
          }
        })
      } else {
        setValue("file.assetUrl", campaignVideo.s3Url)
        setValue("file.assetType", "image/png")
      }
    }
  }, [campaignVideo])

  React.useEffect(() => {
    if (selectedZones?.length || selectedZonePlacements.length) {
      const storeAreaIds = selectedZones.map((zone) => zone.id)
      const placementIds = selectedZonePlacements
        .flatMap((selection) => selection.placementIds)
        .filter((id): id is number => id !== undefined)

      const bannerIds =
        selectedBanners?.filter((v): v is number => v !== undefined) || []
      const states: string[] =
        selectedStates?.filter((v): v is string => v !== undefined) || []

      // Always pass all available values, let the API handle filtering
      debouncedFetchStores(
        storeAreaIds,
        selectedPropertyValues?.filter(
          (v): v is number => typeof v === "number"
        ) || [],
        placementIds,
        bannerIds,
        states
      )
    }
  }, [
    selectedZones.length,
    selectedPropertyValues?.length,
    selectedBanners.length,
    selectedStates.length,
    selectedZonePlacements.length
  ])

  const fetchStoresByZoneIds = async (
    zoneIds: number[],
    selectedPropertyValues: number[] = [],
    zonePlacementIds: number[] = [],
    bannerIds: number[] = [],
    states: string[] = []
  ) => {
    if (zoneIds.length > 0 || zonePlacementIds.length > 0) {
      setIsLoadingStores(true)

      const validPropertyValues: number[] = selectedPropertyValues.filter(
        (value: number | undefined): value is number => value !== undefined
      )

      try {
        const storesRes = await fetchStores(
          zoneIds,
          zonePlacementIds.length > 0 ? zonePlacementIds : [],
          bannerIds.length > 0 ? bannerIds : [],
          states.length > 0 ? states : [],
          validPropertyValues.length > 0 ? validPropertyValues : []
        )

        const newStores = storesRes.data.sort(compareNameFn())

        setStores(newStores)

        const filteredActiveStores = activeStores.filter((storeId) =>
          newStores.some((store: { id: number }) => store.id === storeId)
        )

        setValue("activeStores", filteredActiveStores)
      } catch (error) {
        setStoreLoadingError(error as Error)
      } finally {
        setIsLoadingStores(false)
      }
    }
  }

  const debouncedFetchStores = debounce(fetchStoresByZoneIds, 300)

  const currentStartTimestamp = campaignData?.campaignDetails?.startDate
  const currentStartDate = currentStartTimestamp
    ? dayjs(Number(currentStartTimestamp))
    : undefined

  const currentEndTimestamp = campaignData?.campaignDetails?.endDate
  const currentEndDate = currentEndTimestamp
    ? dayjs(Number(currentEndTimestamp))
    : undefined

  const hasCampaignChanged = React.useMemo(() => {
    if (
      targetingSubmissionError &&
      (selectedZones?.length || selectedZonePlacements.length)
    ) {
      return true
    }
    if (!campaignId || !initialValues.current) return false

    const initial = initialValues.current

    return (
      !isEqual(name?.trim(), initial.name?.trim()) ||
      !isEqual(startDate, initial.startDate) ||
      !isEqual(endDate, initial.endDate) ||
      !isEqual(isNoEndDate, initial.isNoEndDate) ||
      !isEqual(selectedZones, initial.selectedZones) ||
      !isEqual(selectedZonePlacements, initial.selectedZonePlacements) ||
      !isEqual(selectedBanners, initial.selectedBanners) ||
      !isEqual(selectedStates, initial.selectedStates) ||
      !isEqual(selectedPropertyValues, initial.selectedPropertyValues) ||
      !isEqual(activeStores.sort(), initial.activeStores.sort())
    )
  }, [
    campaignId,
    name,
    startDate,
    endDate,
    isNoEndDate,
    selectedZones,
    selectedZonePlacements,
    selectedBanners,
    selectedStates,
    selectedPropertyValues,
    activeStores
  ])

  const isSubmitDisabled = React.useMemo(() => {
    if (isSubmitting) return true

    const hasValidSelections =
      (selectedZones?.length || selectedZonePlacements.length) &&
      activeStores?.length > 0 &&
      assetFile?.assetType &&
      assetFile?.assetUrl

    // Edit mode
    if (campaignId) {
      return !hasCampaignChanged || !hasValidSelections
    }

    // Create mode
    return !hasValidSelections || !formState.isValid
  }, [
    isSubmitting,
    campaignId,
    formState.isValid,
    selectedZonePlacements,
    selectedZones,
    hasCampaignChanged,
    activeStores?.length,
    assetFile
  ])

  React.useEffect(() => {
    if (path === "/promotions/create") {
      const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
        if (hasCampaignChanged) {
          e.preventDefault()
          e.returnValue = ""
        }
      }

      window.addEventListener("beforeunload", beforeUnloadHandler)

      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler)
      }
    }
  }, [path, hasCampaignChanged])

  const isCancelDisabled = React.useMemo(() => {
    if (!campaignId) {
      return false
    }

    return !hasCampaignChanged
  }, [hasCampaignChanged, campaignId])

  const handleFileError = (
    fileErrorType: FileErrorType | "clear-error",
    fileName: string
  ) => {
    if (fileErrorType === "clear-error") {
      setFileError(null)
      return
    } else {
      setFileError({ type: fileErrorType, fileName })
    }
  }

  React.useEffect(() => {
    uploadFileQueue.on("add", () => {
      setPendingUploads(true)
    })

    uploadFileQueue.on("idle", () => {
      setPendingUploads(false)
    })
  }, [])

  React.useEffect(() => {
    if (localStorage.getItem("discardChange") === "true") {
      setDiscardUnSavedChange(true)
      localStorage.removeItem("discardChange")

      setTimeout(() => {
        setDiscardUnSavedChange(false)
      }, 5000)
    }
  }, [discardUnSavedChange])

  React.useEffect(() => {
    if (localStorage.getItem("PublishChange") === "true") {
      setPublishChange(true)
      localStorage.removeItem("PublishChange")

      setTimeout(() => {
        setPublishChange(false)
      }, 5000)
    }
  }, [publishChange])

  React.useEffect(() => {
    const handleAutoSubmission = async () => {
      const bannerId = bannersData?.banners?.[0]?.id ?? null
      const isUpdateOperation = !!campaignId

      if (
        fileUploadResponse &&
        !failedToUploadAsset &&
        isSubmitting &&
        !pendingUploads
      ) {
        try {
          await handleCampaignSubmission(
            isUpdateOperation,
            formMethods.getValues(),
            bannerId,
            fileUploadResponse.id,
            Math.floor(fileUploadResponse.duration / 1000)
          )
        } catch (error) {
          console.error(error)
        } finally {
          setIsSubmitting(false)
        }
      } else if (
        !fileUploadResponse &&
        isSubmitting &&
        !pendingUploads &&
        isUpdateOperation
      ) {
        try {
          await handleCampaignSubmission(
            isUpdateOperation,
            formMethods.getValues(),
            bannerId
          )
        } catch (error) {
          console.error(error)
        } finally {
          setIsSubmitting(false)
        }
      }
    }

    handleAutoSubmission()
  }, [
    fileUploadResponse,
    isSubmitting,
    pendingUploads,
    bannersData,
    failedToUploadAsset
  ])

  const handleFileUpload = async (
    name: string,
    aspectRatio: string,
    type: string,
    asset: File,
    duration?: number
  ) => {
    const maxDurationZoneId = getMaxDurationZoneId(
      selectedZones,
      selectedZonePlacements,
      campaignZonePlacementList
    )

    try {
      const fields: FieldsType = {
        isTemplate: false,
        name: name,
        aspectRatio: aspectRatio,
        storeAreasId: maxDurationZoneId
      }

      if (!type.startsWith("video/")) {
        fields.duration = duration
      }

      const uploadResponse = await uploadCampaignVideo({
        fields,
        file: asset
      })

      if (uploadResponse.error?.message) {
        setError("file", {
          type: "server",
          message: uploadResponse.error?.message.replace("[GraphQL]", "").trim()
        })
        setValue("file", {
          assetUrl: "",
          assetType: "",
          duration: 0,
          assetId: null
        })
        setFailedToUploadAsset(true)

        if (isSubmitting) {
          setIsSubmitting(false)
        }
        return null
      } else {
        setFileUploadResponse({
          id: uploadResponse.data.uploadCampaignVideo.id,
          duration: Math.floor(
            uploadResponse.data.uploadCampaignVideo.duration / 1000
          )
        })
        return uploadResponse.data
      }
    } catch (error) {
      console.error(error)
      setFailedToUploadAsset(true)
    }
  }

  const handleCampaignSubmission = async (
    isUpdateOperation: boolean,
    data: CampaignFormModel,
    bannerId: number,
    assetId?: number,
    duration?: number
  ) => {
    if (!selectedZones?.length && !selectedZonePlacements?.length) {
      console.error("No selected zones or zone placements to submit.")
      setFailedToPublish(true)
      return
    }
    const selection = (data.activeStores ?? []).map((store) => ({
      storeId: store,
      type: "store",
      bannerId
    }))

    const startDate = data.startDate as Dayjs
    const endDate = data.endDate as Dayjs

    const isNoEndDate = dayjs(endDate).isSame(dayjs(4092624000000))

    const aspectRatio = getZoneRatio(
      selectedZones,
      selectedZonePlacements,
      campaignZonePlacementList
    )

    // Save Campaign
    const saveCampaignFields = {
      campaignTypesId: 3,
      companiesId: currentUser.userDetails.companiesId,
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
      creativeAspectRatio: aspectRatio,
      isGlobal: false,
      name: data.name,
      selection,
      isEvergreen: isNoEndDate,
      ...(assetId ? { tmpVideo: assetId } : {}),
      ...(duration ? { duration } : {}),
      ...(campaignId ? { id: campaignId } : {}),
      ...((selectedPropertyValues || []).length > 0
        ? { propertyValueIds: selectedPropertyValues }
        : {})
    }

    const saveCampaignResponse = await saveCampaign({
      fields: saveCampaignFields
    })

    if (saveCampaignResponse.error?.message) {
      setFailedToPublish(true)
    } else if (saveCampaignResponse.data.saveCampaignDetails) {
      const campaignId = saveCampaignResponse.data.saveCampaignDetails.id

      const trackingData = {
        campaignStartDate: saveCampaignFields.startDate,
        campaignEndDate: saveCampaignFields.endDate,
        campaignName: saveCampaignResponse.data.saveCampaignDetails.name,
        campaignStoreCount:
          saveCampaignResponse.data.saveCampaignDetails.activeStores?.length
      }

      const zonesResponse = await handleTargetingDetailsSubmission(
        campaignId,
        selectedZones,
        selectedZonePlacements,
        selectedBanners,
        selectedStates
      )

      if (zonesResponse.error?.message) {
        setFailedToPublish(true)
        navigate(`/campaigns/${campaignId}`)
        return
      }
      if (isUpdateOperation) {
        saveCampaignResponse.data.saveCampaignDetails

        amplitude.track("editCampaign", trackingData)
        localStorage.setItem("PublishChange", "true")
        window.location.reload()
      } else {
        amplitude.track("createCampaign", trackingData)
        onDidSuccessfullyPublishCampaign(
          saveCampaignResponse.data.saveCampaignDetails
        )
      }
    }
  }

  const handleTargetingDetailsSubmission = async (
    campaignId: number,
    selectedZones: ZonePlacement[],
    selectedZonePlacements: {
      zoneId: number
      placementIds: (number | undefined)[]
    }[],
    selectedBanners: (number | undefined)[],
    selectedStates: (string | undefined)[]
  ) => {
    try {
      const { zone_ids, placement_ids } = extractZonePlacementIdsForSubmission(
        selectedZones,
        selectedZonePlacements
      )

      await Promise.allSettled([
        http.post(
          `${process.env.REACT_APP_REST_API_PATH}/campaigns/${campaignId}/targeting_details/zone_placements`,
          { zone_ids, placement_ids },
          { withCredentials: true }
        ),
        http.post(
          `${process.env.REACT_APP_REST_API_PATH}/campaigns/${campaignId}/targeting_details/filters`,
          { banner_ids: selectedBanners, states: selectedStates },
          { withCredentials: true }
        )
      ])

      return { data: { success: true } }
    } catch (error) {
      console.error("Failed to handle promotion targeting submission:", error)
      return {
        error: { message: "An error occurred during targeting submission" }
      }
    }
  }

  const onSubmitClick = () => {
    const isStartingToday = (startDate as Dayjs).isSame(dayjs(), "day")
    if (isStartingToday && !campaignId) {
      setShowScheduleModal(true)
    } else {
      setIsSubmitting(true)
    }
  }

  const onSubmitConfirm = () => {
    setShowScheduleModal(false)
    setIsSubmitting(true)
  }

  const handleDiscardModalClose = () => {
    setOpenDiscardModal(false)
  }

  const handleDiscardCampaign = () => {
    if (campaignId) {
      localStorage.setItem("discardChange", "true")
      window.location.reload()
    } else {
      navigate(-1)
    }
  }
  const handleBackToCampaignList = () => {
    if (!hasCampaignChanged) {
      navigate("/")
    } else {
      setOpenDiscardModal(true)
    }
  }

  const handleEndNowClick = () => {
    setShowEndNowModal(true)
  }

  const handleEndNowConfirm = async () => {
    setShowEndNowModal(false)
    await handleEndNowSubmission()
  }

  const showEndNow = React.useMemo(() => {
    if (isInitializing || !campaignId || !campaignData?.campaignDetails)
      return false

    const { startDate } = campaignData.campaignDetails
    const today = dayjs().startOf("day")
    const campaignStartDate = dayjs(Number(startDate)).startOf("day")

    return (
      campaignStatus !== "Ended" &&
      (campaignStartDate.isSame(today) || campaignStartDate.isBefore(today))
    )
  }, [isInitializing, campaignId, campaignData?.campaignDetails])

  const handleEndNowSubmission = async () => {
    setIsEndingCampaign(true)
    try {
      const yesterday = dayjs().subtract(1, "day")
      const selection = [
        {
          storeId: 18345,
          bannerId: 975,
          type: "store"
        }
      ]

      const saveCampaignFields = {
        id: campaignId,
        campaignTypesId: 3,
        companiesId: currentUser.userDetails.companiesId,
        startDate: dayjs(
          Number(campaignData?.campaignDetails?.startDate)
        ).format(DATE_FORMAT),
        endDate: yesterday.format(DATE_FORMAT),
        name: campaignData?.campaignDetails?.name,
        isGlobal: false,
        selection
      }

      const saveCampaignResponse = await saveCampaign({
        fields: saveCampaignFields
      })

      if (saveCampaignResponse.error?.message) {
        setFailedToPublish(true)
      } else {
        onDidSuccessfullyEndCampaign()
      }
    } catch (error) {
      setFailedToPublish(true)
    } finally {
      setIsEndingCampaign(false)
    }
  }

  const loaderTitle = campaignId
    ? "Publishing changes…"
    : "Publishing campaign…"

  const loaderContent =
    "Please do not close or refresh this page until it finishes."

  return (
    <FormProvider {...formMethods}>
      <Loader
        active={isInitializing || isSubmitting || isEndingCampaign}
        title={
          isSubmitting
            ? loaderTitle
            : isEndingCampaign
            ? "Ending campaign…"
            : undefined
        }
        content={isSubmitting || isEndingCampaign ? loaderContent : undefined}
      />
      <form onSubmit={formMethods.handleSubmit(onSubmitClick)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <NavBar />

          {storeLoadingError && (
            <ErrorBanner>
              <Typography variant="body2" color="error">
                Something went wrong. Check your internet connection and refresh
                the page.If the problem persists contact
                <Link
                  href="mailto:support@grocerytv.com"
                  color="error"
                  underline="none"
                >
                  support@grocerytv.com
                </Link>
              </Typography>
            </ErrorBanner>
          )}
          {failedToUploadAsset && (
            <Box>
              <ErrorBanner>
                <Typography variant="body2" color="error">
                  There was an issue uploading asset, please try again.If the
                  problem persists contact support@grocerytv.com
                </Typography>
              </ErrorBanner>
            </Box>
          )}
          {failedToPublish && (
            <Box>
              <ErrorBanner>
                <Typography variant="body2" color="error">
                  There was an issue publishing this campaign, please try again.
                </Typography>
              </ErrorBanner>
            </Box>
          )}
          <Box
            sx={{
              width: "100%",
              height: "100%",
              lineHeight: "64px",
              marginTop: "64px",
              justifyContent: "space-around",
              flexDirection: "column"
            }}
            // gap="48px"
            display="flex"
          >
            {campaignId && (
              <Box
                sx={{
                  position: "sticky",
                  zIndex: 1000,
                  top: "64px",
                  backgroundColor: "secondary.main",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  boxShadow: showBoxShadow
                    ? "-2px 10px 5px -6px rgba(0,0,0,0.1)"
                    : "none",
                  display:
                    (!isInitializing &&
                      !isLoadingStores &&
                      campaignData &&
                      (targetingSubmissionError ||
                        (hasCampaignChanged && campaignId))) ||
                    discardUnSavedChange ||
                    publishChange
                      ? "flex"
                      : "none"
                }}
                flexDirection="column"
                width="100%"
              >
                <Box
                  sx={{
                    padding: "0px 10%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%"
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        width: "100%"
                      }}
                      display="flex"
                      flexDirection="column"
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        height="32px"
                        alignItems="center"
                        justifyContent="center"
                        sx={{ width: "100%" }}
                      >
                        {!isInitializing &&
                          !isLoadingStores &&
                          campaignData &&
                          (targetingSubmissionError ||
                            (hasCampaignChanged && campaignId)) && (
                            <Box>
                              <WarningBanner>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ color: "hint.dark" }}
                                >
                                  {campaignFetchError
                                    ? "Promotion data failed to load. Please fill in all promotion details and save again."
                                    : targetingSubmissionError
                                    ? "Sorry, promotion Zone and Store selections failed to save. Please try again."
                                    : "This promotion has unpublished changes."}
                                </Typography>
                              </WarningBanner>
                            </Box>
                          )}
                        {discardUnSavedChange && (
                          <Box>
                            <DiscardBanner>
                              <Typography
                                variant="subtitle1"
                                sx={{ color: "secondary.main" }}
                              >
                                Changes discarded
                              </Typography>
                            </DiscardBanner>
                          </Box>
                        )}
                        {publishChange && (
                          <Box>
                            <SuccessChangeBanner>
                              <Typography
                                variant="subtitle1"
                                sx={{ color: "secondary.main" }}
                              >
                                Changes were successfully published.
                              </Typography>
                            </SuccessChangeBanner>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            <Box display="flex" justifyContent="center">
              <Box
                sx={{
                  maxWidth: "906px"
                }}
                display="flex"
                gap="24px"
                flexDirection="column"
              >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ fontSize: "20px", marginTop: "24px" }}
                  >
                    {campaignId ? "Promotion Details" : "Schedule Promotion"}
                  </Typography>
                </Box>
                <CampaignForm
                  campaignStatus={campaignStatus}
                  currentStartDate={currentStartDate}
                  currentEndDate={currentEndDate}
                />

                <ZonePlacementSelector
                  campaignStatus={campaignStatus || "Draft"}
                  zonePlacementList={campaignZonePlacementList}
                />

                <TargetSelector
                  campaignStatus={campaignStatus}
                  zonePlacementList={campaignZonePlacementList}
                  campaignStores={stores}
                  isLoadingStores={isLoadingStores}
                  storePropertyList={campaignStorePropertyList}
                  campaignBannerList={campaignBannerList}
                  campaignStateList={campaignStateList}
                  isBannerExpanded={isBannerExpanded}
                  isStateExpanded={isStateExpanded}
                />

                {fileError && errorComponentMap[fileError.type] && (
                  <ErrorBanner>
                    {React.createElement(errorComponentMap[fileError.type], {
                      name: fileError.fileName
                    })}
                  </ErrorBanner>
                )}
                <PromotionCreative
                  campaignStatus={campaignStatus}
                  handleFileError={handleFileError}
                  uploadFile={handleFileUpload}
                  uploadFileQueue={uploadFileQueue}
                  campaignZonePlacementList={campaignZonePlacementList}
                />
              </Box>
            </Box>
          </Box>
          <EditCampaignNavBar
            campaignId={campaignId}
            onUserCancel={handleBackToCampaignList}
            publishDisabled={isSubmitDisabled}
            cancelDisabled={isCancelDisabled}
            showEndNow={showEndNow}
            onEndNow={handleEndNowClick}
            onSchedule={onSubmitClick}
          />
        </Box>
      </form>
      <ConfirmationModal
        open={openDiscardModal}
        title={
          campaignId
            ? "Changes will be discarded"
            : "New promotion will be discarded"
        }
        content={
          campaignId
            ? "Promotion changes have not been published yet. If you cancel it now, it will be discarded."
            : "This promotion has not been published yet. If you leave now it will be discarded."
        }
        cancelButtonText="Continue Editing"
        confirmButtonText={campaignId ? "Discard Changes" : "Discard Promotion"}
        confirmButtonVariant="warning"
        onCancel={handleDiscardModalClose}
        onConfirm={handleDiscardCampaign}
      />
      <ConfirmationModal
        open={showEndNowModal}
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <InfoOutlineIcon sx={{ color: "error.dark" }} />
            <Typography
              variant="body2"
              sx={{ fontSize: "18px", fontWeight: 600 }}
            >
              End Promotion Immediately
            </Typography>
          </Box>
        }
        width="710px"
        gap="4px"
        marginTop="12px"
        content="Your promotion will stop playing on all screens and you cannot undo this action."
        cancelButtonText="Cancel"
        confirmButtonText="End"
        confirmButtonVariant="warning"
        onCancel={() => setShowEndNowModal(false)}
        onConfirm={handleEndNowConfirm}
      />
      <ConfirmationModal
        open={showScheduleModal}
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <Typography
              variant="body2"
              sx={{
                fontSize: "18px",
                color: "fileInput.dark",
                fontWeight: 600
              }}
            >
              Your promotion is scheduled for today
            </Typography>
          </Box>
        }
        content="Your promotion will go live immediately. To schedule for a later time, please select a different date."
        cancelButtonText="Cancel"
        confirmButtonText="Schedule"
        confirmButtonVariant="primary"
        width="710px"
        gap="4px"
        marginTop="10px"
        onCancel={() => setShowScheduleModal(false)}
        onConfirm={onSubmitConfirm}
      />
    </FormProvider>
  )
}

export default EditPromotion
