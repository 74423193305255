import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const ChevronRight = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2008_487)">
          <mask
            id="mask0_2008_487"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <path
              d="M12 19L19 12L12 5"
              stroke="#0C0C0D"
              strokeWidth="1.5"
              strokeLinecap="square"
            />
            <path
              d="M7 19L14 12L7 5"
              stroke="#0C0C0D"
              strokeWidth="1.5"
              strokeLinecap="square"
            />
          </mask>
          <g mask="url(#mask0_2008_487)">
            <rect width="24" height="24" fill="currentColor" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_2008_487">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default ChevronRight
