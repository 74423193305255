import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const FileUpload = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1372_1932)">
          <mask
            id="mask0_1372_1932"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <path
              d="M7 8.75L12 3.75M12 3.75L17 8.75M12 3.75V16.5"
              stroke="#0C0C0D"
              strokeWidth="1.5"
              strokeLinecap="square"
            />
            <path
              d="M20 17.25V20.25H4V17.25"
              stroke="black"
              strokeWidth="1.5"
            />
          </mask>
          <g mask="url(#mask0_1372_1932)">
            <rect width="24" height="24" fill="currentColor" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1372_1932">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default FileUpload
