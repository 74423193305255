import React from "react"
import {
  Avatar,
  Box,
  Button,
  FormLabel,
  TextField,
  Typography
} from "@mui/material"
import { isEqual, isEmpty } from "lodash"
import { useMutation, gql } from "urql"
import { UserFormSchema, UserFormModel, User } from "../../../models/user"
import { getFirstAndLastName, getInitials } from "../../../utils"
import { useForm, Controller, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useNavigate, useLocation } from "react-router-dom"
import ConfirmationModal from "../../promotions/ConfirmationModal"
import Loader from "../../Loader"

type EditUserFormProps = {
  user: User
}

const SAVE_USER_DETAILS_MUTATION = gql`
  mutation SaveUserDetails($fields: UserInput!) {
    saveUserDetails(fields: $fields) {
      id
      email
      name
      phone
    }
  }
`
const EditUserForm = ({ user }: EditUserFormProps) => {
  const [valuesChanged, setValuesChanged] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const userInitial = getInitials(user.name)
  const { firstName, lastName } = getFirstAndLastName(user.name)

  const initialValues: UserFormModel = {
    userPhone: user.phone,
    userFName: firstName,
    userLName: lastName,
    userEmail: user.email
  }

  const formMethods = useForm<UserFormModel>({
    resolver: yupResolver(UserFormSchema),
    mode: "onBlur",
    criteriaMode: "all"
  })

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid }
  } = formMethods

  const currentValues = watch()

  const [, EditUserDetails] = useMutation(SAVE_USER_DETAILS_MUTATION)

  React.useEffect(() => {
    if (!isEqual(initialValues, currentValues)) {
      setValuesChanged(true)
    } else {
      setValuesChanged(false)
    }
  }, [currentValues])

  const handleCancelEditAccount = () => {
    if (valuesChanged) {
      setOpenModal(true)
    } else {
      navigate(-1)
    }
  }

  const handleModalClose = () => {
    setOpenModal(false)
  }

  const handleDiscardEditAccount = () => {
    navigate(-1)
  }

  const onSubmit = async (data: UserFormModel) => {
    setIsLoading(true)
    const fieldData = {
      id: user.id,
      companiesId: user.companiesId,
      email: data.userEmail,
      name: data.userLName
        ? `${data.userFName} ${data.userLName}`
        : data.userFName,
      phone: data.userPhone,
      stores: [],
      notifications: []
    }
    const response = await EditUserDetails({ fields: fieldData })

    if (response.error) {
      console.error(response.error.message)
    } else {
      navigate("/account", {
        state: { ...location.state, saved: true, fromEditPage: true }
      })
    }
    setIsLoading(false)
  }

  return (
    <FormProvider {...formMethods}>
      <Loader active={isLoading} />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap="48px"
        marginTop="165px"
        marginX="10%"
      >
        <Avatar
          sx={{
            height: "72px",
            width: "72px",
            fontSize: "24px",
            fontWeight: 600,
            bgcolor: "avatar.main"
          }}
        >
          {userInitial}
        </Avatar>

        <Box
          display="flex"
          justifyContent="space-between"
          width="480px"
          alignItems="center"
        >
          <Typography variant="body1" fontSize="18px">
            Account Information
          </Typography>
          <Box display="flex" gap="12px">
            <Button
              variant="outlined"
              sx={{ height: "32px", fontWeight: 600, color: "text.primary" }}
              onClick={handleCancelEditAccount}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              form="edit-account-form"
              type="submit"
              sx={{
                height: "32px",
                fontWeight: 600,
                color: "secondary.main",
                boxShadow: "none"
              }}
              disabled={!valuesChanged || !isValid || !isEmpty(errors)}
            >
              Save Changes
            </Button>
          </Box>
        </Box>
        <form id="edit-account-form" onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" width="480px" flexDirection="column" gap="16px">
            <Controller
              name="userFName"
              control={control}
              defaultValue={initialValues.userFName}
              render={({ field }) => (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    gap: "8px",
                    alignItems: "center"
                  }}
                >
                  <FormLabel
                    htmlFor="user-first-name"
                    sx={{ fontSize: "14px" }}
                  >
                    First name
                  </FormLabel>
                  <TextField
                    {...field}
                    id="user-first-name"
                    variant="outlined"
                    sx={{ width: "75%" }}
                    error={Boolean(errors.userFName)}
                    helperText={
                      errors.userFName ? errors.userFName.message : null
                    }
                  />
                </Box>
              )}
            />
            <Controller
              name="userLName"
              control={control}
              defaultValue={initialValues.userLName}
              render={({ field }) => (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    gap: "8px",
                    alignItems: "center"
                  }}
                >
                  <FormLabel htmlFor="user-last-name" sx={{ fontSize: "14px" }}>
                    Last name
                  </FormLabel>
                  <TextField
                    {...field}
                    id="user-last-name"
                    variant="outlined"
                    sx={{ width: "75%" }}
                    error={Boolean(errors.userLName)}
                    helperText={
                      errors.userLName ? errors.userLName.message : null
                    }
                  />
                </Box>
              )}
            />
            <Controller
              name="userEmail"
              control={control}
              defaultValue={initialValues.userEmail}
              render={({ field }) => (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    gap: "8px",
                    alignItems: "center"
                  }}
                >
                  <FormLabel htmlFor="user-email" sx={{ fontSize: "14px" }}>
                    Email
                  </FormLabel>
                  <TextField
                    {...field}
                    id="user-email"
                    variant="outlined"
                    sx={{ width: "75%" }}
                    error={Boolean(errors.userEmail)}
                    helperText={
                      errors.userEmail ? errors.userEmail.message : null
                    }
                  />
                </Box>
              )}
            />
            <Controller
              name="userPhone"
              control={control}
              defaultValue={initialValues.userPhone}
              render={({ field }) => (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    gap: "8px",
                    alignItems: "center"
                  }}
                >
                  <FormLabel htmlFor="user-phone" sx={{ fontSize: "14px" }}>
                    Phone number
                  </FormLabel>
                  <TextField
                    {...field}
                    id="user-phone"
                    variant="outlined"
                    sx={{ width: "75%" }}
                    error={Boolean(errors.userPhone)}
                    helperText={
                      errors.userPhone ? errors.userPhone.message : null
                    }
                  />
                </Box>
              )}
            />
          </Box>
        </form>
        <ConfirmationModal
          open={openModal}
          title="Changes will be discarded"
          content="you have made changes to your account. If you cancel it now, they will be discarded."
          cancelButtonText="Continue Editing"
          confirmButtonText="Discard Changes"
          confirmButtonVariant="warning"
          onCancel={handleModalClose}
          onConfirm={handleDiscardEditAccount}
        />
      </Box>
    </FormProvider>
  )
}

export default EditUserForm
