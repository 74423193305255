/* eslint-disable indent */
import React, { useState, useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import NavBar from "../../navbar"
import EmptyState from "../../promotions/EmptyState"
import PromotionListTable from "../../promotions/PromotionListTable"
import PaginationBar from "../../promotions/PaginationBar"
import PromotionListTableHeader from "../../promotions/PromotionListTableHeader"
import debounce from "lodash/debounce"
import * as amplitude from "@amplitude/analytics-browser"
import { Box, Typography, Dialog, Link } from "@mui/material"
import { GridApiCommon } from "@mui/x-data-grid"
import { Campaign, Status, StatusList } from "../../../models/campaign"
import { http } from "../../App"
import { ZonePlacement } from "../../../models/zoneplacement"
import { Store, StoreBanner } from "../../../models/storetarget"

import ErrorBanner from "../../banners/ErrorBanner"
import EditPromotion from "../EditPromotion"
import SuccessBanner from "../../banners/SuccessBanner"

export type SearchParameters = {
  page: number
  pageSize: number
  search: string
  sortCol: string
  sortOrder: string
  areaFilter: number[]
  statusFilter: string[]
}

interface PropertyValue {
  id: number
  name: string
  is_editable: boolean
}

interface PropertyApiResponse {
  id: number
  name: string
  type: string
  values: PropertyValue[]
}

const useDebouncedSearchTracking = () => {
  const [lastSentSearchTrackTime, setLastSentSearchTrackTime] =
    React.useState<number>(Date.now())

  const trackSearch = React.useCallback(
    (searchText: string) => {
      const currentTime = Date.now()

      if (
        searchText.trim() !== "" &&
        currentTime - lastSentSearchTrackTime >= 5000
      ) {
        amplitude.track("searchCampaign", { searchText })
        setLastSentSearchTrackTime(currentTime)
      }
    },
    [lastSentSearchTrackTime]
  )

  return React.useCallback(
    debounce(trackSearch, 3000, {
      leading: false,
      trailing: true
    }),
    [trackSearch]
  )
}

const PromotionList = () => {
  const [showEmptyState, setShowEmptyState] = React.useState<
    boolean | undefined
  >(undefined)
  const [campaigns, setCampaigns] = useState<Campaign[]>([])
  const [fetching, setFetching] = useState<boolean>(true)
  const [totalCampaigns, setTotalCampaigns] = useState<number>(0)
  const [campaignFetchError, setCampaignFetchError] = useState<
    Error | undefined
  >(undefined)
  const [checkedStoreAreas, setCheckedStoreAreas] = useState<ZonePlacement[]>(
    []
  )
  const [storePropertyList, setStorePropertyList] = useState<
    PropertyApiResponse[]
  >([])
  const [checkedStatuses, setCheckedStatuses] = useState<string[]>([])
  const [publishedCampaign, setPublishedCampaign] = useState<
    Campaign | undefined
  >(undefined)
  const [zonePlacementList, setZonePlacementList] = useState<ZonePlacement[]>(
    []
  )
  const debouncedTrackSearch = useDebouncedSearchTracking()
  const [campaignBannerList, setCampaignBannerList] = useState<StoreBanner[]>(
    []
  )
  const [campaignStateList, setCampaignStateList] = useState<string[]>([])

  const initialApiRef = {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const apiRef = useRef<GridApiCommon<any, any>>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    initialApiRef as GridApiCommon<any, any>
  )

  const showEditPromotionScreen =
    window.location.pathname.includes("/promotions/")

  const selectedCampaignId: number | undefined =
    Number(window.location.pathname.split("/")[2]) || undefined

  const navigate = useNavigate()

  useEffect(() => {
    if (publishedCampaign !== undefined) {
      setTimeout(() => {
        setPublishedCampaign(undefined)
      }, 5000)
    }
  }, [publishedCampaign])

  useEffect(() => {
    Promise.all([
      http.get(
        `${process.env.REACT_APP_REST_API_PATH}/campaigns/zone_placements`,
        {
          withCredentials: true
        }
      ),
      http.get(`${process.env.REACT_APP_REST_API_PATH}/store_properties`, {
        withCredentials: true
      }),
      http.get(`${process.env.REACT_APP_REST_API_PATH}/stores`, {
        withCredentials: true
      })
    ])
      .then(([areasResponse, propertiesResponse, storesResponse]) => {
        setZonePlacementList(areasResponse.data)
        setStorePropertyList(propertiesResponse.data)
        // Extract unique banners
        const uniqueBanners: StoreBanner[] = Array.from(
          new Map(
            (storesResponse.data as Store[])
              .filter((store: Store) => store.banner)
              .map((store: Store) => [store.banner.id, store.banner])
          ).values()
        )
        // Extract unique states
        const uniqueStates: string[] = Array.from(
          new Set(
            (storesResponse.data as Store[])
              .filter(
                (store): store is Store & { address: { state: string } } =>
                  store.address?.state != null
              )
              .map((store) => store.address.state)
          )
        ).sort()

        setCampaignBannerList(uniqueBanners)
        setCampaignStateList(uniqueStates)
      })
      .catch((error) => {
        console.error("Failed to fetch zone placements or properties:", error)
      })
  }, [])

  const [searchParameters, setSearchParameters] = useState<SearchParameters>({
    page: 1,
    pageSize: 100,
    search: "",
    sortCol: "",
    sortOrder: "",
    areaFilter: [],
    statusFilter: []
  })

  type PaginatedSearchParameters = {
    page: number
    pageSize: number
    search?: string
    statusFilter?: string
    areaFilter?: string
  }

  useEffect(() => {
    setFetching(true)
    const parameters: PaginatedSearchParameters = {
      pageSize: searchParameters.pageSize,
      page: searchParameters.page
    }
    if (searchParameters.search !== "") {
      parameters.search = searchParameters.search
    }
    if (searchParameters.statusFilter.length > 0) {
      parameters.statusFilter = JSON.stringify(searchParameters.statusFilter)
    }
    if (searchParameters.areaFilter.length > 0) {
      parameters.areaFilter = JSON.stringify(searchParameters.areaFilter)
    }
    http
      .get(`${process.env.REACT_APP_REST_PLATFORM_API_PATH}/v2/campaigns`, {
        withCredentials: true,
        params: parameters
      })
      .then((res) => {
        const campaignList: Campaign[] = []
        setTotalCampaigns(res.data.total)
        if (showEmptyState === undefined) {
          setShowEmptyState(res.data.total > 0 ? false : true)
        }
        for (const paginatedCampaign of res.data.paginatedCampaigns) {
          campaignList.push({
            id: paginatedCampaign.id,
            name: paginatedCampaign.name,
            startDate: new Date(
              parseInt(paginatedCampaign.startDate)
            ).toLocaleDateString(),
            endDate: new Date(
              parseInt(paginatedCampaign.endDate)
            ).toLocaleDateString(),
            statusDisplay: paginatedCampaign.statusDisplay,
            status: paginatedCampaign.status,
            duration: paginatedCampaign.duration,
            storeCount: paginatedCampaign.activeSummary,
            videoId: paginatedCampaign.videosId,
            tmpVideo: paginatedCampaign.tmpVideos,
            activeSummary: paginatedCampaign.activeSummary,
            activeStores: [],
            storeAreaName: paginatedCampaign.storeAreaName
          })
        }
        setCampaigns(campaignList)
        setFetching(false)
      })
      .catch((error) => {
        console.error(error)
        setCampaignFetchError(error)
      })
  }, [searchParameters])

  useEffect(() => {
    if (apiRef.current && campaigns.length > 0) {
      apiRef.current?.scrollToIndexes({ rowIndex: 0, colIndex: 0 })
    }
  }, [searchParameters.page])

  const handleSearchInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value
    setSearchParameters((state) => ({
      ...state,
      search: value
    }))
    debouncedTrackSearch(value)
  }

  const handleCheckAllStatus = (isChecked: boolean) => {
    if (isChecked) {
      setCheckedStatuses([...StatusList])
    } else {
      setCheckedStatuses([])
    }
    setSearchParameters((state) => ({
      ...state,
      statusFilter: isChecked ? [...StatusList] : []
    }))
  }

  const handleStatusChange = (isChecked: boolean, statusName: string) => {
    const index = checkedStatuses.indexOf(statusName)
    let newCheckedStatuses = [...checkedStatuses]
    if (isChecked && index === -1) {
      newCheckedStatuses.push(statusName)
    } else if (!isChecked && index > -1) {
      newCheckedStatuses = newCheckedStatuses.filter(
        (status) => status !== statusName
      )
    }

    setSearchParameters((state) => ({
      ...state,
      statusFilter: newCheckedStatuses
    }))
    setCheckedStatuses(newCheckedStatuses)
    if (newCheckedStatuses.length > 0) {
      amplitude.track("filterCampaigns", {
        statusFilters: newCheckedStatuses
      })
    }
  }

  const handleStoreAreaChange = (
    isChecked: boolean,
    storeArea: ZonePlacement
  ) => {
    const index = checkedStoreAreas.indexOf(storeArea)
    const newStoreAreas = [...checkedStoreAreas]

    if (isChecked && index === -1) {
      newStoreAreas.push(storeArea)
    } else if (!isChecked && index > -1) {
      newStoreAreas.splice(index, 1)
    }

    setSearchParameters((state) => ({
      ...state,
      areaFilter: newStoreAreas.map((storeArea) => storeArea.id)
    }))
    setCheckedStoreAreas(newStoreAreas)
    if (newStoreAreas.length > 0) {
      amplitude.track("filterCampaigns", {
        storeAreaFilters: newStoreAreas.map((area) => area.name)
      })
    }
  }

  const handleClearInput = () => {
    setSearchParameters((state) => ({
      ...state,
      search: ""
    }))
  }

  const handleResetAll = () => {
    setCheckedStatuses([])
    setCheckedStoreAreas([])
    setSearchParameters((state) => ({
      ...state,
      page: 1,
      search: "",
      sortCol: "",
      sortOrder: "",
      areaFilter: [],
      statusFilter: []
    }))
  }

  const selectedCampaignStatus = campaigns.find(
    (campaign) => campaign.id === selectedCampaignId
  )?.status

  return (
    <Box
      sx={{
        margin: "0 10%",
        padding: "0",
        height: "100%",
        paddingTop: "64px"
      }}
      display="flex"
      flexDirection={"column"}
    >
      <Dialog
        open={showEditPromotionScreen}
        onClose={() => {
          navigate(-1)
        }}
        fullScreen
      >
        <EditPromotion
          onDidSuccessfullyPublishCampaign={(campaign: Campaign) => {
            navigate(-1)
            handleClearInput()
            setPublishedCampaign(campaign)
          }}
          onDidSuccessfullyEndCampaign={() => {
            navigate(-1)
            handleClearInput()
          }}
          campaignId={selectedCampaignId}
          campaignStatus={selectedCampaignStatus as Status | undefined}
          campaignZonePlacementList={zonePlacementList}
          campaignStorePropertyList={storePropertyList}
          campaignBannerList={campaignBannerList}
          campaignStateList={campaignStateList}
        />
      </Dialog>
      <NavBar />

      {/* Test box to visualize banner position */}
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          position: "fixed",
          top: "64px",
          left: 0,
          right: 0,
          zIndex: 1100,
          padding: "8px"
        }}
      >
        {campaignFetchError !== undefined && (
          <ErrorBanner sx={{ marginTop: "10px" }}>
            <Typography variant="body2" color="error">
              Something happened, try refreshing the page. If the issue
              persists, contact
              <Link
                href="mailto:support@grocerytv.com"
                color="error"
                underline="none"
              >
                support@grocerytv.com
              </Link>
            </Typography>
          </ErrorBanner>
        )}

        {publishedCampaign !== undefined && (
          <SuccessBanner sx={{ marginTop: "10px" }}>
            <Typography variant="body2" color="success">
              Promotion was successfully scheduled.
            </Typography>
          </SuccessBanner>
        )}
      </Box>

      <Box
        sx={{
          width: "100%",
          position: "sticky",
          top: "32px",
          backgroundColor: "white",
          zIndex: 1000,
          paddingTop: "48px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "36px",
            marginBottom: "24px"
          }}
        >
          <Typography variant="h3" sx={{ fontSize: "20px" }}>
            Promotions
          </Typography>
        </Box>
        {!showEmptyState && (
          <PromotionListTableHeader
            zonePlacementList={zonePlacementList}
            searchInput={searchParameters.search}
            checkedStoreAreas={checkedStoreAreas}
            checkedStatuses={checkedStatuses}
            handleSearchInput={handleSearchInput}
            handleClearInput={handleClearInput}
            handleResetAll={handleResetAll}
            handleCheckAllStatus={handleCheckAllStatus}
            handleStoreAreaChange={handleStoreAreaChange}
            handleStatusChange={handleStatusChange}
          />
        )}
      </Box>

      {!showEmptyState && (
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            width: "100%"
          }}
        >
          <Box
            sx={{
              marginTop: "24px"
            }}
          >
            <PromotionListTable
              campaigns={campaigns}
              searchInput={searchParameters.search}
              handleClearInput={handleClearInput}
              showLoadingIndicator={fetching}
              apiRef={apiRef}
            />
            <PaginationBar
              pagination={{
                page: searchParameters.page,
                pageSize: searchParameters.pageSize,
                total: totalCampaigns
              }}
              onPageChange={(page) => {
                setSearchParameters((state) => ({
                  ...state,
                  page
                }))
              }}
            />
          </Box>
        </Box>
      )}

      {!fetching && showEmptyState && <EmptyState />}
    </Box>
  )
}

export default PromotionList
