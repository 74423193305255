import * as React from "react"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker as Picker } from "@mui/x-date-pickers/DatePicker"
import { Typography } from "@mui/material"
import Calendar from "../icons/Calendar"
import dayjs from "dayjs"
import { FieldErrors } from "react-hook-form"

interface Props {
  onChange: (e: dayjs.Dayjs | null) => void
  value: dayjs.Dayjs | null
  label: string
  minDate?: dayjs.Dayjs
  maxDate?: dayjs.Dayjs
  onBlur: React.FocusEventHandler<HTMLInputElement>
  disabled?: boolean
  width?: string
  errors?: FieldErrors
  name?: string
  placeholder?: string
  style?: React.CSSProperties
}

const DatePicker = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      onChange,
      value,
      label,
      minDate,
      maxDate,
      disabled,
      onBlur,
      width = "200px",
      errors,
      name,
      placeholder = "MM/DD/YYYY",
      style
    },
    ref
  ) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography variant="body2">{label}</Typography>
        <Picker
          sx={{ width, ...style }}
          slots={{
            openPickerIcon: Calendar
          }}
          slotProps={{
            textField: {
              onBlur: onBlur,
              error: Boolean(name && errors?.[name]),
              helperText: name ? (errors?.[name]?.message as string) : "",
              inputRef: ref,
              placeholder: placeholder
            }
          }}
          format="MM/DD/YYYY"
          value={value}
          onChange={onChange}
          maxDate={maxDate}
          minDate={minDate}
          disabled={disabled}
        />
      </LocalizationProvider>
    )
  }
)

DatePicker.displayName = "DatePicker"

export default DatePicker
