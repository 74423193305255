/* eslint-disable indent */
import React from "react"
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Tooltip
} from "@mui/material"
import { useFormContext } from "react-hook-form"
import ZonePlacementDisplay from "../ZonePlacementDisplay"
import { ZonePlacement } from "../../../models/zoneplacement"
import { CampaignFormModel, Status } from "../../../models/campaign"
import {
  generateDurationList,
  getSelectedZoneRatios,
  getMaxDurationSeconds
} from "../../../utils"
import ConfirmationModal from "../ConfirmationModal"
import { useFlags } from "launchdarkly-react-client-sdk"
import InfoIcon from "../../icons/InfoIcon"
import HoverIcon from "../../icons/HoverIcon"
import InfoOutlineIcon from "../../icons/InfoOutlineIcon"

interface Props {
  zonePlacementList: ZonePlacement[] | undefined
  campaignStatus: Status
}

interface Column {
  id: "name" | "orientation" | "placements"
  label: string
  width: number
  align?: "right" | "left" | "center"
}

const columns: Column[] = [
  { id: "name", label: "Name", width: 400 },
  { id: "orientation", label: "Screen Orientation", width: 253 },
  { id: "placements", label: "Placements", width: 253, align: "right" }
]

const ZonePlacementSelector = ({
  zonePlacementList,
  campaignStatus
}: Props) => {
  const { watch, setValue } = useFormContext<CampaignFormModel>()

  const [openResetConfirmationModal, setOpenResetConfirmationModal] =
    React.useState(false)
  const [tempZone, setTempZone] = React.useState<ZonePlacement | null>(null)
  const [tempPlacementId, setTempPlacementId] = React.useState<
    number | undefined
  >(undefined)

  const selectedZones = watch("selectedZones") || []
  const activeStores = watch("activeStores")
  const selectedZonePlacements = watch("selectedZonePlacements") || []
  const file = watch("file")

  const PLACEABLE_ZONE_NAMES = ["Deli - Menu", "Beer and Wine"]
  const { placementTargeting } = useFlags()

  // Check if zone is allowed to show placements
  const isZonePlacementEnabled = React.useCallback(
    (zoneName: string) => {
      if (placementTargeting) return true

      return PLACEABLE_ZONE_NAMES.includes(zoneName)
    },
    [placementTargeting]
  )

  const handleSelectZone = React.useCallback(
    (zonePlacement: ZonePlacement, isLastPlacementDeselection = false) => {
      const isZoneSelected = selectedZones.some(
        (zone) => zone.id === zonePlacement.id
      )

      const hasActiveStores = (activeStores?.length ?? 0) > 0
      const hasFile = file?.asset !== undefined
      const fileDuration = file?.duration || 0
      const newZoneMaxDurationSeconds = Math.floor(
        (zonePlacement.max_creative_duration_ms ?? 30000) / 1000
      )

      const shouldShowFileWarning =
        hasFile && fileDuration > newZoneMaxDurationSeconds

      if (shouldShowFileWarning) {
        setTempZone(zonePlacement)
        setOpenResetConfirmationModal(true)
        return
      }

      if ((isZoneSelected || isLastPlacementDeselection) && hasActiveStores) {
        setTempZone(zonePlacement)
        setOpenResetConfirmationModal(true)
        return
      }

      if (
        hasFile &&
        // Only check when we're removing (deselecting) something
        (isZoneSelected || isLastPlacementDeselection) &&
        // Check if this removal will result in no selections
        ((selectedZones.length <= 1 && selectedZonePlacements.length === 0) ||
          (selectedZones.length === 0 && selectedZonePlacements.length <= 1) ||
          (selectedZones.length === 1 && selectedZonePlacements.length === 1))
      ) {
        setValue("file", {
          asset: undefined,
          assetUrl: "",
          assetType: "",
          duration: 6,
          assetId: null
        })
      }

      const shouldDeselect = isZoneSelected || isLastPlacementDeselection

      const newSelectedZones = shouldDeselect
        ? selectedZones.filter((zone) => zone.id !== zonePlacement.id)
        : [...selectedZones, zonePlacement]

      const newSelectedZonePlacements = shouldDeselect
        ? selectedZonePlacements.filter(
            (selection) => selection.zoneId !== zonePlacement.id
          )
        : [
            ...selectedZonePlacements.filter(
              (s) => s.zoneId !== zonePlacement.id
            ),
            {
              zoneId: zonePlacement.id,
              placementIds: zonePlacement.placements?.map((p) => p.id) || []
            }
          ]

      setValue("selectedZones", newSelectedZones)
      setValue("selectedZonePlacements", newSelectedZonePlacements)
    },
    [selectedZones, selectedZonePlacements, activeStores, file]
  )

  const isCampaignEnded = campaignStatus === "Ended"

  const isZoneDisabled = (area: ZonePlacement) => {
    if (selectedZones.length === 0 && selectedZonePlacements.length === 0) {
      return false
    }

    const selectedRatios = getSelectedZoneRatios(
      selectedZones,
      selectedZonePlacements,
      zonePlacementList || []
    )

    return !selectedRatios.has(area.ratio)
  }

  const minDurationSeconds = 6

  const maxDurationSeconds = React.useMemo(
    () =>
      getMaxDurationSeconds(
        selectedZones,
        selectedZonePlacements,
        zonePlacementList ?? []
      ),
    [selectedZones, selectedZonePlacements, zonePlacementList]
  )

  React.useEffect(() => {
    const durationList = generateDurationList(
      minDurationSeconds ?? 6,
      maxDurationSeconds
    )

    setValue("durationList", durationList)
  }, [minDurationSeconds, maxDurationSeconds])

  const handleConfirmChange = () => {
    const hasFile = file?.asset !== undefined
    const fileDuration = file?.duration || 0
    const tempZoneMaxDurationSeconds = Math.floor(
      (tempZone?.max_creative_duration_ms ?? 30000) / 1000
    )

    if (hasFile && fileDuration > tempZoneMaxDurationSeconds) {
      setValue("file", {
        asset: undefined,
        assetUrl: "",
        assetType: "",
        duration: 6,
        assetId: null
      })
    }

    if (tempZone && !tempPlacementId) {
      const newSelectedZones =
        selectedZones?.filter((zone) => zone.id !== tempZone.id) || []
      const newSelectedZonePlacements =
        selectedZonePlacements?.filter(
          (selection) => selection.zoneId !== tempZone.id
        ) || []

      setValue("selectedZones", newSelectedZones)
      setValue("selectedZonePlacements", newSelectedZonePlacements)
      setValue("storeSearchTerm", "")

      if (
        newSelectedZones.length === 0 &&
        newSelectedZonePlacements.length === 0
      ) {
        setValue("activeStores", [])
      }

      setTempZone(null)
    } else if (tempPlacementId) {
      const selection = selectedZonePlacements?.find(
        (selection) => selection.placementIds?.includes(tempPlacementId)
      )

      if (selection) {
        const newPlacements =
          selection.placementIds?.filter((id) => id !== tempPlacementId) || []

        const newSelectedZonePlacements =
          newPlacements.length > 0
            ? selectedZonePlacements?.map((item) =>
                item.zoneId === selection.zoneId
                  ? { ...item, placementIds: newPlacements }
                  : item
              )
            : selectedZonePlacements?.filter(
                (item) => item.zoneId !== selection.zoneId
              )

        const newSelectedZones =
          selectedZones?.filter((zone) => zone.id !== selection.zoneId) || []

        setValue("selectedZones", newSelectedZones)
        setValue("selectedZonePlacements", newSelectedZonePlacements)
        setValue("storeSearchTerm", "")

        if (
          newSelectedZones.length === 0 &&
          newSelectedZonePlacements.length === 0
        ) {
          setValue("activeStores", [])
          if (hasFile) {
            setValue("file", {
              asset: undefined,
              assetUrl: "",
              assetType: "",
              duration: 6,
              assetId: null
            })
          }
        }
      }

      setTempPlacementId(undefined)
    }

    const newSelectedZones =
      selectedZones?.filter((zone) => zone.id !== tempZone?.id) || []
    const newSelectedZonePlacements =
      selectedZonePlacements?.filter(
        (selection) => selection.zoneId !== tempZone?.id
      ) || []

    if (
      newSelectedZones.length === 0 &&
      newSelectedZonePlacements.length === 0
    ) {
      setValue("activeStores", [])
      setValue("file", {
        asset: undefined,
        assetUrl: "",
        assetType: "",
        duration: 6,
        assetId: null
      })
      setValue("storeSearchTerm", "")
    }

    setOpenResetConfirmationModal(false)
  }

  const handleResetConfirmationModalClose = () => {
    setOpenResetConfirmationModal(false)
    setTempZone(null)
    setTempPlacementId(undefined)
  }

  return (
    <Box
      sx={{
        width: "100%",
        minWidth: "720px"
      }}
      data-cy="store-area-selector"
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        <Typography
          sx={{
            fontSize: "18px"
          }}
          variant="body1"
          data-cy="store-area-selector"
        >
          Select Zones and Placements
        </Typography>
        <TableContainer
          sx={{
            border: "1px solid",
            borderColor: "secondary.light",
            borderRadius: "8px",
            overflowY: "auto",
            maxHeight: "396px",
            overflowX: "hidden",
            "& .MuiTableBody-root tr:last-child td": {
              borderBottom: "none"
            }
          }}
        >
          <Table
            sx={{
              tableLayout: "fixed",
              "& .MuiTableCell-root": {
                paddingY: 0,
                height: "36px"
              }
            }}
          >
            <TableHead sx={{ opacity: isCampaignEnded ? 0.5 : 1 }}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{ width: column.width }}
                  >
                    {column.id === "placements" ? (
                      <Box
                        display="flex"
                        sx={{
                          justifyContent: "flex-end",
                          alignItems: "center"
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{ color: "fileInput.dark" }}
                        >
                          {placementTargeting ||
                          zonePlacementList?.some((zone) =>
                            PLACEABLE_ZONE_NAMES.includes(zone.name)
                          )
                            ? column.label
                            : ""}
                        </Typography>
                        <Tooltip
                          title="Use this field to target specific screens within a zone."
                          placement="bottom-end"
                          TransitionProps={{ style: { marginTop: "4px" } }}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                maxWidth: "180px",
                                borderRadius: "4px",
                                paddingLeft: "12px",
                                lineHeight: "15px"
                              }
                            }
                          }}
                        >
                          <span>
                            <HoverIcon
                              hover={
                                <InfoIcon
                                  sx={{
                                    height: "16px",
                                    width: "16px"
                                  }}
                                />
                              }
                              normal={
                                <InfoOutlineIcon
                                  sx={{
                                    height: "16px",
                                    width: "16px"
                                  }}
                                />
                              }
                              sx={{
                                height: "16px",
                                width: "16px",
                                cursor: "pointer"
                              }}
                            />
                          </span>
                        </Tooltip>
                      </Box>
                    ) : (
                      <Typography
                        variant="body2"
                        sx={{ color: "fileInput.dark" }}
                      >
                        {column.label}
                      </Typography>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {zonePlacementList?.map((zonePlacement) => (
                <ZonePlacementDisplay
                  isSelected={selectedZones.some(
                    (zone) => zone.id === zonePlacement.id
                  )}
                  key={zonePlacement.id}
                  zonePlacement={zonePlacement}
                  onSelectZone={handleSelectZone}
                  disabled={isZoneDisabled(zonePlacement)}
                  isCampaignEnded={isCampaignEnded}
                  setOpenResetConfirmationModal={setOpenResetConfirmationModal}
                  setTempPlacementId={setTempPlacementId}
                  showPlacements={isZonePlacementEnabled(zonePlacement.name)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <ConfirmationModal
        open={openResetConfirmationModal}
        title="Targeting and creative will be updated"
        content="Your store selection and creative may be updated based on the remaining zones. Do you want to proceed?"
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        confirmButtonVariant="primary"
        onCancel={handleResetConfirmationModalClose}
        onConfirm={handleConfirmChange}
      />
    </Box>
  )
}

export default ZonePlacementSelector
