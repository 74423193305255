import React from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"

const StartIconFill = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_6464)">
          <mask
            id="mask0_1_6464"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="21"
            height="20"
          >
            <mask id="path-1-inside-1_1_6464" fill="white">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5 17.5C14.6421 17.5 18 14.1421 18 10C18 5.85786 14.6421 2.5 10.5 2.5C6.35786 2.5 3 5.85786 3 10C3 14.1421 6.35786 17.5 10.5 17.5ZM9.75679 13.0529L14.7568 8.46953L13.7432 7.3638L9.27256 11.4619L7.28033 9.46967L6.21967 10.5303L8.71967 13.0303L9.22744 13.5381L9.75679 13.0529Z"
              />
            </mask>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.5 17.5C14.6421 17.5 18 14.1421 18 10C18 5.85786 14.6421 2.5 10.5 2.5C6.35786 2.5 3 5.85786 3 10C3 14.1421 6.35786 17.5 10.5 17.5ZM9.75679 13.0529L14.7568 8.46953L13.7432 7.3638L9.27256 11.4619L7.28033 9.46967L6.21967 10.5303L8.71967 13.0303L9.22744 13.5381L9.75679 13.0529Z"
              fill="#0C0C0D"
            />
            <path
              d="M14.7568 8.46953L15.7704 9.57526L16.8761 8.56168L15.8625 7.45595L14.7568 8.46953ZM9.75679 13.0529L10.7704 14.1586L10.7704 14.1586L9.75679 13.0529ZM13.7432 7.3638L14.8489 6.35021L13.8354 5.24448L12.7296 6.25807L13.7432 7.3638ZM9.27256 11.4619L8.2119 12.5226L9.22744 13.5381L10.2861 12.5676L9.27256 11.4619ZM7.28033 9.46967L8.34099 8.40901L7.28033 7.34835L6.21967 8.40901L7.28033 9.46967ZM6.21967 10.5303L5.15901 9.46967L4.09835 10.5303L5.15901 11.591L6.21967 10.5303ZM8.71967 13.0303L7.65901 14.091L7.65901 14.091L8.71967 13.0303ZM9.22744 13.5381L8.16678 14.5988L9.18233 15.6143L10.241 14.6438L9.22744 13.5381ZM16.5 10C16.5 13.3137 13.8137 16 10.5 16V19C15.4706 19 19.5 14.9706 19.5 10H16.5ZM10.5 4C13.8137 4 16.5 6.68629 16.5 10H19.5C19.5 5.02944 15.4706 1 10.5 1V4ZM4.5 10C4.5 6.68629 7.18629 4 10.5 4V1C5.52944 1 1.5 5.02944 1.5 10H4.5ZM10.5 16C7.18629 16 4.5 13.3137 4.5 10H1.5C1.5 14.9706 5.52944 19 10.5 19V16ZM13.7432 7.3638L8.74321 11.9471L10.7704 14.1586L15.7704 9.57526L13.7432 7.3638ZM12.6375 8.37739L13.6511 9.48312L15.8625 7.45595L14.8489 6.35021L12.6375 8.37739ZM10.2861 12.5676L14.7568 8.46953L12.7296 6.25807L8.25897 10.3562L10.2861 12.5676ZM6.21967 10.5303L8.2119 12.5226L10.3332 10.4012L8.34099 8.40901L6.21967 10.5303ZM7.28033 11.591L8.34099 10.5303L6.21967 8.40901L5.15901 9.46967L7.28033 11.591ZM9.78033 11.9697L7.28033 9.46967L5.15901 11.591L7.65901 14.091L9.78033 11.9697ZM10.2881 12.4774L9.78033 11.9697L7.65901 14.091L8.16678 14.5988L10.2881 12.4774ZM8.74321 11.9471L8.21386 12.4324L10.241 14.6438L10.7704 14.1586L8.74321 11.9471Z"
              fill="#0C0C0D"
              mask="url(#path-1-inside-1_1_6464)"
            />
          </mask>
          <g mask="url(#mask0_1_6464)">
            <rect x="0.5" width="20" height="20" fill="#28282F" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1_6464">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}

export default StartIconFill
