import React from "react"
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Typography,
  Tooltip
} from "@mui/material"
import { NewCampaignStore } from "../../models/campaign"

interface PropertyStoreSelectorTableProps {
  missingStores: NewCampaignStore[]
  selectedStoreId: number | null
  onStoreSelect: (storeId: number) => void
}

const PropertyStoreSelectorTable = ({
  missingStores,
  selectedStoreId,
  onStoreSelect
}: PropertyStoreSelectorTableProps) => {
  return (
    <Box
      sx={{
        overflowY: "auto"
      }}
    >
      <Table
        stickyHeader
        sx={{
          "& .MuiTableCell-root": {
            padding: "0 8px",
            width: "33.33%",
            height: "36px"
          },
          "& .MuiTableCell-head:first-of-type": {
            paddingLeft: "16px"
          }
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Store</TableCell>
            <TableCell>Banner</TableCell>
            <TableCell>City, State</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {missingStores.map((store) => (
            <TableRow
              key={store.id}
              onClick={() => onStoreSelect(store.id)}
              sx={{
                cursor: "pointer",
                backgroundColor:
                  selectedStoreId === store.id ? "hint.light" : "inherit"
              }}
            >
              <TableCell>
                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={selectedStoreId === store.id}
                    disableRipple
                    readOnly
                    sx={{
                      color: "secondary.dark",
                      height: "36px",
                      width: "36px",
                      borderRadius: "50%",
                      "&.Mui-checked": {
                        color: "primary.light"
                      }
                    }}
                  />
                  <Tooltip
                    title={store.name}
                    placement="bottom-start"
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [40, -5]
                            }
                          }
                        ]
                      }
                    }}
                  >
                    <Typography variant="body2">{store.name}</Typography>
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={store.banner?.name || "N/A"}
                  placement="bottom-start"
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [40, -5]
                          }
                        }
                      ]
                    }
                  }}
                >
                  <Typography variant="body2">
                    {store.banner?.name || "N/A"}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip
                  title={`${store.address?.city || ""}, ${
                    store.address?.state || ""
                  }`}
                  placement="bottom-start"
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [40, -5]
                          }
                        }
                      ]
                    }
                  }}
                >
                  <Typography variant="body2">
                    {`${store.address?.city || ""}, ${
                      store.address?.state || ""
                    }`}
                  </Typography>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default PropertyStoreSelectorTable
