import React, { useEffect } from "react"
import { Box, Typography } from "@mui/material"
import StoreMappingGrid from "..//StoreMappingGrid"
import { useWatch } from "react-hook-form"
import { StoreTargetMapping } from "../../../models/storetarget"
import { NewCampaignStore } from "../../../models/campaign"

interface MapStoreStepProps {
  setAssignStoresComplete: React.Dispatch<React.SetStateAction<boolean>>
  stores: NewCampaignStore[]
  isEditMode: boolean
}

const MapStoresStep = ({
  setAssignStoresComplete,
  stores,
  isEditMode
}: MapStoreStepProps) => {
  const propertyName = useWatch({
    name: "propertyName"
  })

  const StoreTargetMappings = useWatch({
    name: "StoreTargetMappings",
    defaultValue: []
  })

  useEffect(() => {
    if (StoreTargetMappings.length === 0) return

    const allMapped = (StoreTargetMappings as StoreTargetMapping[]).every(
      (mapping) => mapping.propertyValue && mapping.propertyValue.trim() !== ""
    )
    setAssignStoresComplete(allMapped)
  }, [StoreTargetMappings])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "40px",
        minHeight: "560px",
        backgroundColor: "secondary.contrastText",
        gap: "24px",
        overflowY: "hidden"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          overflowY: "hidden"
        }}
      >
        <Box sx={{ display: "flex", gap: "4px" }}>
          <Typography variant="body2" sx={{ fontSize: "20px" }}>
            Assign Stores to
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontWeight: 700, fontSize: "20px" }}
          >
            {propertyName}
          </Typography>
        </Box>
        <Typography variant="body1">
          Select stores and assign them to each store group.
        </Typography>
      </Box>

      <StoreMappingGrid data={stores} isEditMode={isEditMode} />
    </Box>
  )
}

export default MapStoresStep
