/* eslint-disable indent */
import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Typography,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@mui/material"
import NavBar from "../../navbar"
import Loader from "../../Loader"
import ConfirmationModal from "../../promotions/ConfirmationModal"
import { http } from "../../App"
import CircleAdd from "../../icons/CircleAdd"
import SettingOutlined from "../../icons/SettingOutlined"
import InfoOutlineIcon from "../../icons/InfoOutlineIcon"
import InfoIcon from "../../icons/InfoIcon"
import HoverIcon from "../../icons/HoverIcon"
import ArrowRight from "../../icons/ArrowRight"
import { NewCampaignStore, StoreProperty } from "../../../models/campaign"
import { StoreBanner } from "../../../models/storetarget"
import CautionFill from "../../icons/CautionFill"
import PropertyStoreSelectorTable from "../../PropertyStoreSelectorTable"
interface PropertyValue {
  id: string
  name: string
  is_editable: boolean
}

interface PropertyApiResponse {
  id: number
  name: string
  type: string
  values: PropertyValue[]
}

const StorePropertyList = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState<PropertyApiResponse[]>([])
  const [selectedFilter, setSelectedFilter] = useState<string>("banner")
  const [selectedBanner, setSelectedBanner] = useState<string | null>(null)
  const [selectedState, setSelectedState] = useState<string | null>(null)
  const [stores, setStores] = useState<NewCampaignStore[]>([])
  const [storesRequiringValues, setStoresRequiringValues] = useState<
    NewCampaignStore[]
  >([])
  const [storeBannerList, setStoreBannerList] = useState<StoreBanner[]>([])
  const [storeStateList, setStoreStateList] = useState<string[]>([])
  const [selectedPropertyValueId, setSelectedPropertyValueId] = useState<
    string | null
  >(null)
  const [selectedStoreId, setSelectedStoreId] = useState<number | null>(null)

  const [missingPropertyMapping, setMissingPropertyMapping] = useState<{
    [key: string]: { id: string; name: string }
  }>({})
  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false)

  const fetchData = async () => {
    try {
      setLoading(true)
      const [
        propertiesResponse,
        storesRequiringValuesResponse,
        allStoresResponse
      ] = await Promise.all([
        http.get(`${process.env.REACT_APP_REST_API_PATH}/store_properties`, {
          withCredentials: true
        }),
        http.get(
          `${process.env.REACT_APP_REST_API_PATH}/stores/requiring_property_values`,
          {
            withCredentials: true
          }
        ),
        http.get(`${process.env.REACT_APP_REST_API_PATH}/stores`, {
          withCredentials: true
        })
      ])

      const fetchedProperties = propertiesResponse.data
      const fetchedStoresRequiringValues = storesRequiringValuesResponse.data
      const fetchedAllStores = allStoresResponse.data

      // Set properties state
      if (fetchedProperties.length > 0) {
        fetchedProperties.forEach((property: PropertyApiResponse) => {
          property.values.sort((a, b) => {
            if (a.name === "Not Applicable") return 1
            if (b.name === "Not Applicable") return -1
            return 0
          })
        })

        setProperties(fetchedProperties)
        setSelectedPropertyValueId(fetchedProperties[0].values[0].id)
      }

      // Create unique banners and states BEFORE setting state
      const uniqueStoreBanners: StoreBanner[] = Array.from(
        new Map(
          (fetchedAllStores as NewCampaignStore[])
            .filter((store) => store.banner)
            .map((store) => [store.banner.id, store.banner])
        ).values()
      )

      const uniqueStoreStates: string[] = Array.from(
        new Set(
          (fetchedAllStores as NewCampaignStore[])
            .filter((store) => typeof store.address?.state === "string")
            .map((store) => store.address.state as string)
        )
      ).sort()

      // Set all states at once
      setStores(fetchedAllStores)
      setStoresRequiringValues(fetchedStoresRequiringValues)
      setStoreBannerList(uniqueStoreBanners)
      setStoreStateList(uniqueStoreStates)

      // Set initial selections if available
      if (uniqueStoreBanners.length > 0) {
        setSelectedBanner(String(uniqueStoreBanners[0].id))
      }
      if (uniqueStoreStates.length > 0) {
        setSelectedState(uniqueStoreStates[0])
      }

      // Automatically select the first store if available
      if (fetchedStoresRequiringValues.length > 0) {
        setSelectedStoreId(fetchedStoresRequiringValues[0].id)

        const selectedStore = fetchedStoresRequiringValues[0]
        const storePropertyNames = selectedStore.properties.map(
          (prop: StoreProperty) => prop.property_name
        )

        const missingProperties: PropertyApiResponse[] =
          fetchedProperties.filter((prop: PropertyApiResponse) => {
            return !storePropertyNames.includes(prop.name)
          })

        const initialMissingPropertyMapping = missingProperties.reduce<{
          [key: string]: { id: string; name: string }
        }>((acc, prop: PropertyApiResponse) => {
          acc[prop.name] = { id: "", name: "" }
          return acc
        }, {})

        setMissingPropertyMapping(initialMissingPropertyMapping)
      }
    } catch (error) {
      console.error("Failed to fetch data:", error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleFilterSwitch = (newFilter: string) => {
    setSelectedFilter(newFilter)

    if (newFilter === "banner") {
      if (storeBannerList.length > 0) {
        setSelectedBanner(String(storeBannerList[0].id))
      }
    } else if (newFilter === "state") {
      if (storeStateList.length > 0) {
        setSelectedState(storeStateList[0])
      }
    } else {
      const selected = properties.find(
        (property) => property.name === newFilter
      )
      if (selected) {
        setSelectedPropertyValueId(
          selected.values.length > 0 ? selected.values[0].id : null
        )
      }
    }
  }

  const handlePropertyValueSwitch = (newPropertyValueId: string) => {
    setSelectedPropertyValueId(newPropertyValueId)
  }

  const getStoreCountForValue = (valueId: string) => {
    return stores.filter((store) =>
      store.properties.some((property) => property.value_id === valueId)
    ).length
  }

  const handleStoreSelection = (storeId: number) => {
    if (storeId !== selectedStoreId) {
      setSelectedStoreId(storeId)

      // Find the selected store
      const selectedStore = storesRequiringValues.find(
        (store) => store.id === storeId
      )

      if (selectedStore) {
        // Get the names of properties already present in the store
        const storePropertyNames = selectedStore.properties.map(
          (prop) => prop.property_name
        )

        // Find missing properties by comparing store's properties with fetched properties
        const missingProperties = properties.filter(
          (prop) => !storePropertyNames.includes(prop.name)
        )

        const initialValues = missingProperties.reduce<{
          [key: string]: { id: string; name: string }
        }>((acc, prop: PropertyApiResponse) => {
          acc[prop.name] = { id: "", name: "" }
          return acc
        }, {})

        setMissingPropertyMapping(initialValues)
      }
    }
  }

  const handleDropdownChange = (
    propertyName: string,
    valueId: string,
    valueName: string
  ) => {
    setMissingPropertyMapping((prevMapping) => ({
      ...prevMapping,
      [propertyName]: { id: valueId, name: valueName } // Store both value ID and name
    }))
  }

  const isSaveDisabled = Object.values(missingPropertyMapping).some(
    (value) => value.name === ""
  )

  const handleSaveClick = async () => {
    try {
      setLoading(true)
      console.log("Submitting missing properties: ", missingPropertyMapping)

      await Promise.all(
        Object.keys(missingPropertyMapping).map((propertyName) => {
          const selectedProperty = missingPropertyMapping[propertyName]
          return http.post(
            `${process.env.REACT_APP_REST_API_PATH}/stores/${selectedStoreId}/store_property_values/${selectedProperty.id}`,
            {},
            { withCredentials: true }
          )
        })
      )

      console.log(
        `Successfully submitted properties for store ${selectedStoreId}`
      )
      await fetchData()
    } catch (error) {
      console.error("Failed to submit properties: ", error)
    } finally {
      setLoading(false)
      setOpenConfirmationModal(false)
    }
  }

  const handleConfirmationModalClose = () => {
    setOpenConfirmationModal(false)
  }
  const handleEditProperty = () => {
    const selectedPropertyData = properties.find(
      (property) => property.name === selectedFilter
    )
    if (
      selectedPropertyData &&
      selectedFilter !== "banner" &&
      selectedFilter !== "state"
    ) {
      navigate(`/storeproperty/edit/${selectedPropertyData.id}`)
    }
  }

  const totalContentCount = 6 + Object.keys(missingPropertyMapping).length * 2

  const modalTitle = (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <InfoOutlineIcon sx={{ height: "18px", color: "error.main" }} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <Typography
          variant="body2"
          sx={{ fontSize: "18px", fontWeight: 600, color: "fileInput.dark" }}
        >
          Are you sure?
        </Typography>
        <Typography
          variant="body2"
          sx={{ fontSize: "14px", color: "fileInput.dark" }}
        >
          <Box component="span" sx={{ fontWeight: "bold", marginRight: "2px" }}>
            {totalContentCount}
          </Box>
          pieces of content will now be
          <Box component="span" sx={{ fontWeight: "bold", marginX: "2px" }}>
            added
          </Box>
          to this store. Are you sure you want to continue?
        </Typography>
      </Box>
    </Box>
  )

  const selectedStore = storesRequiringValues.find(
    (store) => store.id === selectedStoreId
  )

  const modalContent = (
    <Box sx={{ display: "flex", gap: "32px", justifyContent: "space-between" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "45%"
        }}
      >
        <Typography variant="body2" sx={{ color: "fileInput.dark" }}>
          Assign stores
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gap: "12px",
            padding: "12px",
            border: "1px solid",
            borderColor: "secondary.light"
          }}
        >
          <Typography variant="body2">Banner</Typography>
          <Typography variant="body2" sx={{ textAlign: "right" }}>
            {selectedStore?.banner?.name ?? "N/A"}
          </Typography>

          <Typography variant="body2">Store</Typography>
          <Typography variant="body2" sx={{ textAlign: "right" }}>
            {selectedStore?.name ?? "N/A"}
          </Typography>

          <Typography variant="body2">Location</Typography>
          <Typography variant="body2" sx={{ textAlign: "right" }}>
            {selectedStore
              ? `${selectedStore?.address?.city}, ${selectedStore?.address}`
              : "N/A"}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ alignSelf: "center" }}>
        <ArrowRight />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "45%"
        }}
      >
        <Typography variant="body2" sx={{ color: "fileInput.dark" }}>
          Property Value(s)
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            gap: "12px",
            padding: "12px",
            border: "1px solid",
            borderColor: "secondary.light"
          }}
        >
          {Object.keys(missingPropertyMapping).map((key) => (
            <React.Fragment key={key}>
              <Typography variant="body2">
                {key.charAt(0).toUpperCase() + key.slice(1)}
              </Typography>
              <Typography variant="body2" sx={{ textAlign: "right" }}>
                {missingPropertyMapping[key].name ?? "N/A"}
              </Typography>
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Box>
  )

  return (
    <Box sx={{ overflow: "hidden", minHeight: "100vh" }}>
      <NavBar />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: "88px 10% 0"
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="body2" sx={{ fontSize: "20px" }}>
            Store Attributes
          </Typography>
          <Tooltip
            title="Store attributes are characteristics or features that help describe your stores. Attributes make it easy to filter and select stores based on shared qualities when scheduling a promotion. Default attributes include Banner and State, but you can create new ones like Region, District, or Pharmacy availability to fit your needs."
            placement="bottom-start"
            sx={{
              padding: "0px",
              margin: "0px"
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: "400px"
                }
              }
            }}
          >
            <span
              style={{
                margin: "0px",
                padding: "0px",
                lineHeight: "0px"
              }}
            >
              <HoverIcon
                hover={
                  <InfoIcon
                    sx={{
                      height: "16px",
                      width: "16px"
                    }}
                  />
                }
                normal={
                  <InfoOutlineIcon
                    sx={{
                      height: "16px",
                      width: "16px"
                    }}
                  />
                }
                sx={{
                  margin: "0px",
                  padding: "0px",
                  height: "16px",
                  width: "16px",
                  cursor: "pointer"
                }}
              />
            </span>
          </Tooltip>
        </Box>
        <Button
          variant="contained"
          onClick={() => {
            navigate("/storeproperty/create")
          }}
          sx={{
            color: "secondary.main",
            boxShadow: "none",
            height: "36px"
          }}
        >
          <CircleAdd sx={{ height: "18px" }} /> Create New Attribute
        </Button>
      </Box>
      {loading ? (
        <Loader active={true} title="Loading" content="Please wait..." />
      ) : (
        <>
          {storesRequiringValues && storesRequiringValues.length > 0 && (
            <Box
              sx={{
                padding: "24px 10% 0"
              }}
            >
              <Box
                sx={{
                  backgroundColor: "info.main",
                  boxShadow: "none",
                  overflow: "hidden",
                  color: "info.dark",
                  borderRadius: "8px"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "12px 16px",
                    backgroundColor: "info.main",
                    height: "72px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "6px"
                    }}
                  >
                    <Box
                      sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                    >
                      <CautionFill
                        sx={{ height: "24px", color: "notification.main" }}
                      />
                      <Box
                        sx={{ display: "flex", gap: "4px" }}
                        data-testid="notification-message"
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "notification.contrastText",
                            fontSize: "16px",
                            fontWeight: 600
                          }}
                        >
                          Needs attention:
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "notification.contrastText",
                            fontSize: "16px"
                          }}
                        >
                          {storesRequiringValues.length}
                          {storesRequiringValues.length === 1
                            ? " store is "
                            : " stores are "}
                          missing attribute information.
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{ display: "flex", gap: "4px", alignItems: "center" }}
                    >
                      <Box sx={{ width: "24px" }} />
                      <Typography
                        variant="body2"
                        sx={{
                          color: "notification.contrastText"
                        }}
                      >
                        Select each store and assign a group for each attribute.
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "24px",
                    padding: "16px"
                  }}
                >
                  <Box
                    sx={{
                      flex: 1,
                      maxHeight: "315px",
                      height: "fit-content",
                      border: "1px solid",
                      backgroundColor: "secondary.main",
                      borderColor: "secondary.light",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      overflow: "hidden"
                    }}
                  >
                    <PropertyStoreSelectorTable
                      missingStores={storesRequiringValues}
                      selectedStoreId={selectedStoreId}
                      onStoreSelect={handleStoreSelection}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: "333px",
                      maxHeight: "315px",
                      height: "fit-content",
                      border: "1px solid",
                      backgroundColor: "secondary.main",
                      borderColor: "secondary.light",
                      borderRadius: "8px",
                      padding: "12px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                      overflowY: "auto"
                    }}
                  >
                    <Typography variant="body2">
                      Assign a group for each attribute
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px"
                      }}
                    >
                      {Object.keys(missingPropertyMapping).map(
                        (propertyName) => (
                          <FormControl key={propertyName} fullWidth>
                            <Typography
                              variant="body2"
                              sx={{ marginBottom: "4px" }}
                            >
                              {propertyName}
                            </Typography>
                            <Select
                              value={
                                missingPropertyMapping[propertyName]?.id || ""
                              }
                              onChange={(event) => {
                                const selectedProperty = properties
                                  .find((prop) => prop.name === propertyName)
                                  ?.values.find(
                                    (value) => value.id === event.target.value
                                  )

                                handleDropdownChange(
                                  propertyName,
                                  selectedProperty?.id || "",
                                  selectedProperty?.name || ""
                                )
                              }}
                              displayEmpty
                              renderValue={(selected) => {
                                const selectedProperty = properties
                                  .find((prop) => prop.name === propertyName)
                                  ?.values.find(
                                    (value) => value.id === selected
                                  )

                                return (
                                  selectedProperty?.name || "Select a value"
                                )
                              }}
                            >
                              {properties
                                .find((prop) => prop.name === propertyName)
                                ?.values.map((value) => (
                                  <MenuItem key={value.id} value={value.id}>
                                    {value.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        )
                      )}
                      <Button
                        variant="contained"
                        color="error"
                        fullWidth
                        disabled={isSaveDisabled}
                        onClick={() => setOpenConfirmationModal(true)}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}

          <Box
            sx={{
              padding: "32px 10% ",
              display: "flex",
              flexDirection: "column",
              gap: "24px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid",
                borderColor: "secondary.light",
                marginX: "-12.5%",
                paddingX: "12.5%"
              }}
            >
              <ToggleButtonGroup
                value={selectedFilter}
                exclusive
                onChange={(event, newButton) => {
                  if (newButton !== null) {
                    handleFilterSwitch(newButton)
                  }
                }}
                sx={{
                  display: "flex",
                  height: "60px",
                  gap: "12px",
                  "& .MuiTypography-root": {
                    fontWeight: 600,
                    textTransform: "capitalize"
                  },
                  "& .MuiToggleButtonGroup-grouped": {
                    border: "none",
                    borderRadius: "0",
                    marginLeft: "0 !important",
                    borderLeft: "none !important"
                  },
                  "& .MuiToggleButtonGroup-grouped.Mui-selected": {
                    backgroundColor: "transparent",
                    borderBottom: "2px solid",
                    borderColor: "primary.light"
                  }
                }}
              >
                <ToggleButton value="banner">
                  <Typography variant="body2">Banner</Typography>
                </ToggleButton>
                <ToggleButton value="state">
                  <Typography variant="body2">State</Typography>
                </ToggleButton>
                {properties.map((item, index) => (
                  <ToggleButton key={index} value={item.name}>
                    <Typography variant="body2">{item.name}</Typography>
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              {selectedFilter === "banner" || selectedFilter === "state" ? (
                <Tooltip
                  title="Banner and state attributes are not editable. Please contact customer service to make changes."
                  placement="bottom-start"
                  sx={{
                    padding: "0px",
                    margin: "0px"
                  }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: "135px"
                      }
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      opacity: 0.5
                    }}
                  >
                    <SettingOutlined
                      sx={{
                        height: "18px"
                      }}
                    />
                    <Typography variant="body2" sx={{ fontWeight: 600 }}>
                      Manage Filter
                    </Typography>
                  </Box>
                </Tooltip>
              ) : (
                <Button
                  variant="outlined"
                  sx={{
                    height: "36px",
                    border: "none",
                    color: "primary.light"
                  }}
                  onClick={handleEditProperty}
                >
                  <SettingOutlined
                    sx={{
                      height: "18px"
                    }}
                  />
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Manage Attribute
                  </Typography>
                </Button>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "16px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  border: "1px solid",
                  borderColor: "secondary.light",
                  borderRadius: "8px",
                  width: "333px",
                  height: "fit-content",
                  overflow: "hidden"
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0 12px",
                      borderBottom: "1px solid",
                      borderColor: "secondary.light"
                    }}
                  >
                    <Typography variant="body2" sx={{ lineHeight: "36px" }}>
                      Value
                    </Typography>
                    <Typography variant="body2" sx={{ lineHeight: "36px" }}>
                      # of stores
                    </Typography>
                  </Box>
                  <Box sx={{ overflowY: "auto", maxHeight: "322px" }}>
                    <ToggleButtonGroup
                      value={
                        selectedFilter === "banner"
                          ? selectedBanner
                          : selectedFilter === "state"
                          ? selectedState
                          : selectedPropertyValueId
                      }
                      orientation="vertical"
                      exclusive
                      onChange={(event, newButton) => {
                        if (newButton !== null) {
                          if (selectedFilter === "banner") {
                            setSelectedBanner(newButton)
                          } else if (selectedFilter === "state") {
                            setSelectedState(newButton)
                          } else {
                            handlePropertyValueSwitch(newButton)
                          }
                        }
                      }}
                      sx={{
                        display: "flex",
                        "& .MuiToggleButtonGroup-grouped": {
                          border: "0",
                          borderRadius: "0"
                        },
                        "& .MuiToggleButtonGroup-grouped.Mui-selected": {
                          backgroundColor: "selectedRow.main"
                        },
                        "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
                          borderBottom: "1px solid",
                          borderColor: "secondary.light"
                        }
                      }}
                    >
                      {selectedFilter === "banner"
                        ? storeBannerList.map((banner, index) => (
                            <ToggleButton
                              key={index}
                              value={String(banner.id)}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                height: "36px"
                              }}
                            >
                              <Typography variant="body2">
                                {banner.name}
                              </Typography>
                              <Typography variant="body2">
                                {
                                  stores.filter(
                                    (store) => store.banner.id === banner.id
                                  ).length
                                }
                              </Typography>
                            </ToggleButton>
                          ))
                        : selectedFilter === "state"
                        ? storeStateList.map((state, index) => (
                            <ToggleButton
                              key={index}
                              value={state}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                height: "36px"
                              }}
                            >
                              <Typography variant="body2">{state}</Typography>
                              <Typography variant="body2">
                                {
                                  stores.filter(
                                    (store) => store.address.state === state
                                  ).length
                                }
                              </Typography>
                            </ToggleButton>
                          ))
                        : properties
                            .find(
                              (property) => property.name === selectedFilter
                            )
                            ?.values.map((item, index) => (
                              <ToggleButton
                                key={index}
                                value={item.id}
                                disabled={item.name === "Not Applicable"}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  height: "36px"
                                }}
                              >
                                <Typography variant="body2">
                                  {item.name}
                                </Typography>
                                <Typography variant="body2">
                                  {getStoreCountForValue(item.id)}
                                </Typography>
                              </ToggleButton>
                            ))}
                    </ToggleButtonGroup>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  border: "1px solid",
                  borderColor: "secondary.light",
                  borderRadius: "8px",
                  height: "360px",
                  overflowY: "auto"
                }}
              >
                <Table
                  stickyHeader
                  sx={{
                    "& .MuiTableCell-root": {
                      padding: "0 8px",
                      width:
                        selectedFilter === "banner" ||
                        selectedFilter === "state"
                          ? "33.33%"
                          : "25%",
                      height: "36px"
                    },
                    "& .MuiTableCell-head:first-of-type": {
                      paddingLeft: "16px"
                    }
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Store</TableCell>
                      <TableCell>Banner</TableCell>
                      <TableCell>City, State</TableCell>
                      {properties.length > 0 &&
                        selectedFilter !== "banner" &&
                        selectedFilter !== "state" && (
                          <TableCell>{selectedFilter}</TableCell>
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      "& .MuiTableRow-root:last-of-type": {
                        "& .MuiTableCell-root": {
                          borderBottom: "none"
                        }
                      }
                    }}
                  >
                    {stores
                      .filter((store) => {
                        if (selectedFilter === "banner") {
                          return store.banner.id === Number(selectedBanner)
                        } else if (selectedFilter === "state") {
                          return store.address.state === selectedState
                        } else {
                          return store.properties.some(
                            (property) =>
                              property.property_name === selectedFilter &&
                              property.value_id === selectedPropertyValueId
                          )
                        }
                      })
                      .map((store, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            cursor: "pointer",
                            backgroundColor:
                              selectedStoreId === store.id
                                ? "hint.light"
                                : "inherit"
                          }}
                        >
                          <TableCell>
                            <Tooltip
                              title={store.name}
                              placement="bottom-start"
                              slotProps={{
                                popper: {
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [40, -5]
                                      }
                                    }
                                  ]
                                }
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  paddingLeft: "8px"
                                }}
                              >
                                {store.name}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={store.banner.name || "N/A"}
                              placement="bottom-start"
                              slotProps={{
                                popper: {
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [40, -5]
                                      }
                                    }
                                  ]
                                }
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"
                                }}
                              >
                                {store.banner.name || "N/A"}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={`${store.address.city}, ${store.address.state}`}
                              placement="bottom-start"
                              slotProps={{
                                popper: {
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [40, -5]
                                      }
                                    }
                                  ]
                                }
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis"
                                }}
                              >
                                {`${store.address.city}, ${store.address.state}`}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          {properties.length > 0 &&
                            selectedFilter !== "banner" &&
                            selectedFilter !== "state" && (
                              <TableCell>
                                <Tooltip
                                  title={
                                    store.properties.find(
                                      (property) =>
                                        property.property_name ===
                                          selectedFilter &&
                                        property.value_id ===
                                          selectedPropertyValueId
                                    )?.value_name || "N/A"
                                  }
                                  placement="bottom-start"
                                  slotProps={{
                                    popper: {
                                      modifiers: [
                                        {
                                          name: "offset",
                                          options: {
                                            offset: [40, -5]
                                          }
                                        }
                                      ]
                                    }
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis"
                                    }}
                                  >
                                    {store.properties.find(
                                      (property) =>
                                        property.property_name ===
                                          selectedFilter &&
                                        property.value_id ===
                                          selectedPropertyValueId
                                    )?.value_name || "N/A"}
                                  </Typography>
                                </Tooltip>
                              </TableCell>
                            )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Box>

          <ConfirmationModal
            open={openConfirmationModal}
            title={modalTitle}
            content={modalContent}
            cancelButtonText="Cancel"
            confirmButtonText="Yes, map this store"
            confirmButtonVariant="error"
            onCancel={handleConfirmationModalClose}
            onConfirm={handleSaveClick}
            width="710px"
          />
        </>
      )}
    </Box>
  )
}

export default StorePropertyList
